import React from 'react';
import styled from 'styled-components';
import { Container, Section, Text12, Text18, Wrapper } from '../../atoms';

export default function ContentContacts({ arr }) {

  return (
    <DivColumn>
      <Section backgroundColor="#5BB990">
        <Container lg>
          <Wrapper both noHeight>
            <DivRow>
              {(arr && arr.length > 0) && arr.map((depart, d) => (
                <Item key={String(d)}>
                  <Title color="#368D67" dangerouslySetInnerHTML={{ __html: depart.titulo }} />
                  <Margin />
                  {(depart?.people && depart?.people?.length > 0) && depart.people.map((people, i) => (
                    <InnerItem key={String(i)}>
                      <Text18 color="#35383B" dangerouslySetInnerHTML={{ __html: people.name }} />
                      <Margin margin={0.5} />
                      <Title color="#368D67" dangerouslySetInnerHTML={{ __html: 'Email' }} />
                      <a href={`mailto:${people.email}`} target="__blank">
                        <Text18 color="#236146" dangerouslySetInnerHTML={{ __html: people.email }} />
                      </a>
                    </InnerItem>
                  ))}
                </Item>
              ))
              }
            </DivRow>
          </Wrapper>
        </Container>
      </Section>
    </DivColumn>
  )
}


const Margin = styled.div`
  margin-top: ${({ margin }) => margin ? margin * 8 : 8}px;
`;
const DivColumn = styled.div`
  display: flex;
  flex-flow: column;
`;
const Title = styled(Text12)`
  text-transform: uppercase;
  letter-spacing: 0.1em;
  font-family: 'AeonikBlack', sans-serif;
`;
const DivRow = styled.div`
  display: flex;
  flex-flow: row;
  flex-wrap: wrap;
  margin-bottom: -60px;
  margin-right: -40px;
  @media screen and (max-width: 880px){
    margin-right: 0;
  }
`;
const Item = styled.div`
  display: flex;
  flex-flow: column;
  margin-bottom: 60px;
  margin-right: 40px; 
  width: calc(33% - 40px);
  @media screen and (max-width: 1024px){
    width: calc(50% - 40px);
  }
  @media screen and (max-width: 700px){
    width: 100%;
    margin-right: 0;
    margin-bottom: 40px;
  }
`;
const InnerItem = styled.div`
  display: flex;
  flex-flow: column;
  margin-bottom: 16px;
  :last-of-type{
    margin-bottom: 0px;
  }
`;
const Mapa = styled.a`
  img{
    width: 100%;
    height: 572px;
    object-fit: cover;
  }
`;