import { ApolloProvider } from '@apollo/client';
import moment from 'moment';
import React from 'react';
import { useCookies } from 'react-cookie';
import { BrowserRouter } from "react-router-dom";
import { Main } from './components/layout';
import client from './utils/client';

function App() {

  const [cookies] = useCookies(['language']);
  moment.locale(cookies.language);
  
  return (
    <ApolloProvider client={client}>
      <BrowserRouter>
        <Main />
      </BrowserRouter>
    </ApolloProvider>
  );
}

export default App;
