import React, { useContext, useEffect, useState } from 'react';
import styled, { css } from 'styled-components';
import { Modal, Text12, H4 } from '../../atoms';
import { Button } from '../../atoms';
import { useCookies } from 'react-cookie';
import { store } from '../../../utils/store';

export default function ModalCookies() {

  const { menu } = useContext(store);

  const {
    titleCookies,
    descriptionCookeis,
    buttonTextCookies
  } = menu;

  const [open, setOpen] = useState(false);
  const [animation, setAnimation] = useState(false);

  const [cookies, setCookie] = useCookies(['cookies']);

  useEffect(() => {
    if (!cookies.cookies) {
      setOpen(true);
    }
  }, [])

  const afterOpenModal = () => {
    setAnimation(true);
  }

  const cookiesAgree = () => {
    setCookie('cookies', 'agree', { path: '/' });
    setAnimation(false);
    setTimeout(() => {
      setOpen(false);
    }, 500)
  }

  return (
    <Modal isOpen={open} afterOpenModal={afterOpenModal}>
      <Overlay animation={animation} />
      <Content animation={animation}>
        <Structure>
          <Texts>
            <Title color='#368D67'>{titleCookies}</Title>
            <Description dangerouslySetInnerHTML={{ __html: descriptionCookeis }} />
          </Texts>
          <Button text={buttonTextCookies} onClick={cookiesAgree}/>
        </Structure>
      </Content>
    </Modal>
  )
}

const Overlay = styled.div`
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background-color: rgba(0,0,0,0);
  transition: all 0.5s ease;

  ${({ animation }) => animation && css`
    background-color: rgba(0,0,0,0.5);
  `}
`;
const Structure = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  box-sizing: border-box;
  padding: 60px 125px;
  background-color: #fff;
  transform: translateY(100%);
  transition: all 0.5s ease;
  justify-content: space-between;
  @media screen and (max-width: 1280px) {
    padding: 60px 90px;
  }
  @media screen and (max-width: 768px) {
    padding: 60px 60px;
    flex-direction: column;
  }
  @media screen and (max-width: 578px) {
    padding: 45px 30px;
    flex-direction: column;
  }
`;
const Texts = styled.div`
  margin-right: 100px;
  flex:1;
  @media screen and (max-width: 768px) {
    margin-right: 0;
  }
`;
const Title = styled(H4)`
  margin-bottom: 12px;
`;
const Description = styled(Text12)`
  a {
    color: white;
    text-decoration: underline;
  }
`;
const Content = styled.div`
  position: relative;
  z-index: 2;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: flex-end;
  opacity: 0;
  transition: all 0.5s ease;

  ${({ animation }) => animation && css`
    opacity: 1;

    ${Structure} {
      transform: translateY(0);
    }
  `};
`;
