import React, { useEffect, useState } from 'react';
import styled, { css } from 'styled-components';
import { Modal } from '../../atoms';

export default function ModalWarning(props) {

  const {
    isOpen,
    children
  } = props;

  const [animation, setAnimation] = useState(false);

  const afterOpenModal = () => {
    setAnimation(true);
  };

  const afterCloseModal = () => {
    setAnimation(false);
  };

  const onRequestClose = () => {
    setAnimation(false);
  };

  useEffect(() => {
    if(isOpen === false){
      onRequestClose();
    }
  }, [isOpen])

  return (
    <Modal isOpen={isOpen} afterOpenModal={afterOpenModal} afterCloseModal={afterCloseModal}>
      <Overlay animation={animation} />
      <Content animation={animation}>
        <Structure>
          {children}
        </Structure>
      </Content>
    </Modal>
  )
}

const Overlay = styled.div`
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background-color: rgba(0,0,0,0);
  transition: all 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94);
  ${({ animation }) => animation && css`
    background-color: rgba(0,0,0,0);
  `}
`;
const Structure = styled.div`
  margin-left: auto;
  width: 594px;
  max-width: 100%;
  min-height: 100%;
  display: flex;
  flex-flow:column;
  box-sizing: border-box;
  background: #368D67;
  transition: all 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94);
  transform: translateY(100%);
`;
const Content = styled.div`
  position: relative;
  z-index: 2;
  width: 100%;
  min-height: 100%;
  display: flex;
  opacity: 0;
  background-color: rgba(53, 56, 59, 0.8);
  transition: all 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94);
  ${({ animation }) => animation && css`
    opacity: 1;
    ${Structure} {
      transform: translateY(0%);
    }
  `};
  @media screen and (max-width: 1200px){
    width:100%;
  }
`;