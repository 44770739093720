// import { useI18Zen } from '@keegpt/i18zen';
import React, { useContext, useEffect, useState } from 'react';
import { useCookies } from 'react-cookie';
import { Link, useHistory, useLocation, useParams } from "react-router-dom";
import Select from 'react-select';
import { ReactSVG } from 'react-svg';
import styled, { css } from 'styled-components';
import { getLanguage, setLanguage } from '../../../utils/language';
import { store } from '../../../utils/store';
import {
  Text14,
  Text16
} from '../../atoms';
import ModalMenu from '../modalMenu';
import ModalSearch from '../modalSearch';

export default function Navbar() {

  const { navbar, menu } = useContext(store);
  const [cookies] = useCookies(['language']);

  let lang = cookies.language;

  let content = menu;
  let history = useHistory();
  const [isOpen, setIsOpen] = useState(false);
  const [isOpenSearch, setIsOpenSearch] = useState(false);

  const [pathName, setPathName] = useState(null);
  let location = useLocation();
  // SHOW FULL NAVBAR

  const [changeColor, setChangeColor] = useState('');

  useEffect(() => {
    setIsOpen(false)
    if (location && location.pathname) {
      let tempPath = location.pathname;
      let pathSplited = tempPath.split('/');
      if (pathSplited[2] === "") {
        setChangeColor('home');
      } else if ((pathSplited[2] === "sobre" || pathSplited[2] === "servicos" || pathSplited[2] === "downloads" || pathSplited[2] === "noticias")) {
        setChangeColor('verde');
      } else if ((pathSplited[2] === "produtos" || pathSplited[2] === "contactos")) {
        setChangeColor('branco');
      } else if (pathSplited[2] === "clientes") {
        setChangeColor('clientes');
      }
      setPathName('/' + pathSplited[2]);
    }
  }, [location]);

  const [show, setShow] = useState(false);

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    }
  })

  const handleScroll = () => {
    let scrollY = window.scrollY;
    let width = window.innerWidth;
    let test = width < 600 ? 60 : 200;
    if (scrollY >= test) {
      if (!show) {
        setShow(true);
      }
    }
    else {
      if (show) {
        setShow(false);
      }
    }
  };

  return (
    <DivNav active={show}>
      <DivRowSpace>
        <DivRow center>
          <Link to="/">
            {changeColor === 'branco' &&
              <Logo src={show ? navbar?.img?.url : navbar?.imgScroll?.url} />
            }
            {changeColor !== 'branco' &&
              <Logo src={show ? navbar?.img?.url : navbar?.img?.url} />
            }
          </Link>
        </DivRow>
        <DivRow>
          <DivRowMenu color={changeColor} show={show.toString()}>
            {(navbar?.list && navbar?.list.length > 0) && navbar.list.map((item, index) => (
              <Link to={`/${lang}${item.link}`} key={String(index)}>
                <TitleSections className={pathName === item.link && 'active'}>
                  {item.title}
                </TitleSections>
              </Link>
            ))}
          </DivRowMenu>
          <ButtonModal onClick={() => setIsOpenSearch(!isOpen)}>
            <Icon color={changeColor} show={show ? show.toString() : undefined} src="/static/images/icon_search.svg" />
          </ButtonModal>
          <ButtonModal onClick={() => setIsOpen(!isOpen)}>
            <Icon color={changeColor} show={show ? show.toString() : undefined} src="/static/images/icon_menu.svg" />
          </ButtonModal>
        </DivRow>
      </DivRowSpace>
      <DivRowMenu>
      </DivRowMenu>

      <ModalMenu isOpen={isOpen} setIsOpen={setIsOpen} toogleModal={() => setIsOpen(!isOpen)} content={content} lang={lang} />
      <ModalSearch isOpen={isOpenSearch} toogleModal={() => setIsOpenSearch(!isOpenSearch)} lang={lang} />
    </DivNav>
  )
}

const DivNav = styled.div`
  display:flex;
  flex-flow: row;
  z-index: 10;
  position: fixed;
  top: 0;
  width: 100%;
  transition: all 0.4s ease-in-out;
  padding: 24px 26px;
  box-sizing: border-box; 
  ${({ active }) => active && css`
    box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.08);
    background-color: rgba(0,0,0,0.5);
  `}
  @media screen and (max-width: 700px){
    padding: 12px 13px;
  }
`;
const ButtonModal = styled.div`
  display: flex;
  flex-flow: row;
  align-items: center;
  justify-content: center;
  transition: all 0.4s ease-in-out;
  margin-right: 24px;
  :last-of-type{
    margin-right: 0;
  }
  :hover{
    cursor: pointer;
  }
`;
const Icon = styled(ReactSVG)`
  svg{
    height: 16px;
    width: 16px;
    max-height: 16px;
    max-width: 16px;
    path {
      stroke: #D8DADC;
    }
    circle{
      stroke: #D8DADC;
    }
  }
  object-fit: contain;
  ${({ color }) => color === 'clientes' || color === 'verde' && css`
    svg{
      path {
        stroke: #236146;
      }
      circle{
        stroke: #236146;
      }
    }
  `}
  ${({ color }) => color === 'branco' && css`
    svg{
      path {
        stroke: #35383B;
      }
      circle{
        stroke: #35383B;
      }
    }
  `}
  ${({ show }) => show === 'true' && css`
    svg{
      path {
        stroke: #D8DADC;
      }
      circle{
        stroke: #D8DADC;
      }
    }
  `}
`;
const DivRowMenu = styled.div`
  display: flex;
  flex-flow: row;
  ${({ color }) => color === "home" && css`
    div {
      color: #D8DADC;
      &.active{
        font-family: 'AeonikBold' ,sans-serif;
        color: #368D67;
      }
    }
  `}
  ${({ color }) => color === "verde" && css`
    div {
      color: #368D67;
      &.active{
        font-family: 'AeonikBold' ,sans-serif;
        color: #236146;
      }
    }
  `}
  ${({ color }) => color === "clientes" && css`
    div {
      color: #236146;
    }
  `}
  ${({ color }) => color === "branco" && css`
    div {
      color: #B2B6B9;
      &.active{
        font-family: 'AeonikBold' ,sans-serif;
        color: #35383B;
      }
    }
  `}
  ${({ show }) => show === 'true' && css`
    div {
      color: #D8DADC;
      &.active{
        font-family: 'AeonikBold' ,sans-serif;
        color: #D8DADC;
      }
    }
  `}
  @media screen and (max-width: 1400px){
    display: none;
  }
`;
const DivRow = styled.div`
  display: flex;
  flex-flow: row;
  ${({ padding }) => padding && css`
    padding: 0 24px; 
  `}
  ${({ center }) => center && css`
    align-items:center;
  `}
`;
const DivRowSpace = styled.div`
  display: flex;
  flex-flow: row;
  justify-content: space-between;
  width:100%;
  align-items: center;
  ${({ padding }) => padding && css`
    padding: 8px 24px; 
  `}
`;
const Logo = styled.img`
  height: 32px;
  max-height: 32px;
  object-fit: contain;
`;
const TitleSections = styled(Text16)`
  box-sizing: border-box;
  transition: all 0.4s ease-in-out;
  margin-right: 24px;
  color: #D8DADC;
  :hover{
    cursor:pointer;
  }
  &.active{
    font-family: 'AeonikBold' ,sans-serif;
    color:#368D67;
  }
  @media screen and (max-width: 1400px){
    margin-bottom: 16px;
    margin-right:0;
  }
`;
const DivToggleLanguage = styled.div`
  min-width: 80px;
  max-width: 80px;
  height: 100%;
  border-left:1px solid #E0E0E0;
  box-sizing: border-box;
  align-items: center;
  justify-content: center;
  @media screen and (min-width:1201px){
    display: flex;
  }
  @media screen and (max-width:1200px){
    display: none;
  }
`;
const Dropdown = styled(Select)`
  height:80px;
  width: 100%;
  font-size: 16px;
  font-weight: 600;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.29;
  letter-spacing: normal;
  color: #333;
  border-radius: 0;
  text-transform: uppercase;
  .dropdown__control {
    background-color: transparent;
    height: 100%;
    border: unset;
    padding: 0 16px;
    :hover {
      border-color: inherit !important;
    }
  }
  .dropdown__control--is-focused{
    outline: none;
    border: unset;
    box-shadow: unset;
    background: #F2F2F2;
    .dropdown__indicator {
      transform: rotate(-180deg);
      color: #2F80ED;
    }
  }
  .dropdown__value-container{
    padding: 0;
  }
  .dropdown__indicator {
    padding: 0px;
    width: 15px;
    color: #828282;
    transition: all 0.4s ease-in-out;
  }
  .dropdown__indicator-separator {
    width: 0px;
  }
  .dropdown__placeholder,
  .dropdown__single-value {
    font-size: 16px;
    font-weight: 600;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.29;
    letter-spacing: normal;
    color: #333;
    margin: 0;
    max-width: 100%;
  }

  .dropdown__menu {
    margin:0;
    border-radius: 0;
    & div {
      .css-1n7v3ny-option {
        background-color: transparent;
      }
    }
  }
  .dropdown__menu-list{
    padding: 0;
  }
  .dropdown__option{
    width: 80px;
    height: 80px;
    background-color: #fff;
    border-bottom: 1px solid #E0E0E0;
    display:flex;
    align-items: center;
    justify-content: center;
    color: #333;
  }
  .dropdown__option:hover{
    background-color: #F2F2F2 !important;
    cursor: pointer;
  }
  .dropdown__option--is-focused{
    background-color: #F2F2F2;
  }
  .dropdown__option--is-selected{
    background-color: #E0E0E0 ;
  }

  ${({ top }) => top && css`
    .dropdown__option{
      width: 100%;
      justify-content: flex-start;
    }
  `}
`;
const DivMenuModal = styled.div`
  display: flex;
  flex-flow:column;
  justify-content: space-between;
  padding: 26px;
`;
const DivItemsModal = styled.div`
  display: flex;
  flex-flow: column;
`;