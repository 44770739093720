import React from 'react'
import styled from 'styled-components';
import { Container, Section, Wrapper } from '../../atoms';
import { Intro } from '../../molecules';

export default function BannerDownloads({ smallTitle, title }) {
  return (
    <DivColumn>
      <Section backgroundColor="#5BB990">
        <Container lg>
          <Wrapper bothInitial noHeight>
            <Intro
              smallTitleColor="#368D67"
              smallTitle={smallTitle}
              titleSize="H2"
              title={title}
              titleColor="#35383B"
              titleMaxWidth="666px"
            />
          </Wrapper>
        </Container>
      </Section>
      <DivIframe>
        <iframe title="download" src="https://downloads.medidata.pt/PageGen.aspx?WMCM_PaginaId=30929" />
      </DivIframe>
    </DivColumn>
  )
}

const DivColumn = styled.div`
  display: flex;
  flex-flow: column;
`;
const DivIframe = styled.div`
  flex: 1;
  display: flex;
  align-items: center;
  padding: 40px 0;
  min-height: 600px;
  iframe{
    width: 100%;
    height: 500px;
  }
`;