import React from 'react';
import styled, { css } from 'styled-components';

import {
  AntTitle,
  H1,
  H2,
  H3,
  H4,
  H5,
  H6,
  Text18,
  Text16,
  Text14,
  Text12,
  Text10,
} from '../../atoms'

export default function Intro({
  // CENTER CONTAINER
  centerContainer,

  // SMALL TITLE
  smallTitle,
  smallTitleColor,
  smallTitleFontFamily,
  smallTitleAlign,

  // TITLE
  titleSize,
  title,
  titleFontFamily,
  titleTextAlign,
  titleLineHeight,
  titleColor,
  titleMaxWidth,
  titleWithCols,
  titleColorHighlight,

  // DESCRIPTION
  descriptionSize,
  description,
  descriptionFontFamily,
  descriptionTextAlign,
  descriptionLineHeight,
  descriptionColor,
  descriptionMaxWidth,
  descriptionWithCols,
}) {

  const renderAntTitle = () => {
    if (smallTitle) {
      return (
        <AntTitle
          color={smallTitleColor}
          align={smallTitleAlign}
          font={smallTitleFontFamily}
          style={{ letterSpacing: '0.1em' }}
        >
          {smallTitle}
        </AntTitle>
      )
    }
  }

  const renderTitle = () => {
    if (title) {
      if (titleSize === 'H1') {
        return (
          <>
            {smallTitle && <Margin margin={2} />}
            <H1
              dangerouslySetInnerHTML={{ __html: title }}
              font={titleFontFamily}
              align={titleTextAlign}
              height={titleLineHeight}
              color={titleColor}
              maxWidth={titleMaxWidth}
              withCols={titleWithCols}
              hightLight={titleColorHighlight}
            />
          </>
        )
      }
      else if (titleSize === 'H2') {
        return (
          <>
            {smallTitle && <Margin margin={2} />}
            <H2
              dangerouslySetInnerHTML={{ __html: title }}
              font={titleFontFamily}
              align={titleTextAlign}
              height={titleLineHeight}
              color={titleColor}
              maxWidth={titleMaxWidth}
              withCols={titleWithCols}
              hightLight={titleColorHighlight}
            />
          </>
        )
      }
      else if (titleSize === 'H3') {
        return (
          <>
            {smallTitle && <Margin margin={2} />}
            <H3
              dangerouslySetInnerHTML={{ __html: title }}
              font={titleFontFamily}
              align={titleTextAlign}
              height={titleLineHeight}
              color={titleColor}
              maxWidth={titleMaxWidth}
              withCols={titleWithCols}
              hightLight={titleColorHighlight}
            />
          </>
        )
      }
      else if (titleSize === 'H4') {
        return (
          <>
            {smallTitle && <Margin margin={2} />}
            <H4
              dangerouslySetInnerHTML={{ __html: title }}
              font={titleFontFamily}
              align={titleTextAlign}
              height={titleLineHeight}
              color={titleColor}
              maxWidth={titleMaxWidth}
              withCols={titleWithCols}
              hightLight={titleColorHighlight}
            />
          </>
        )
      }
      else if (titleSize === 'H5') {
        return (
          <>
            {smallTitle && <Margin margin={2} />}
            <H5
              dangerouslySetInnerHTML={{ __html: title }}
              font={titleFontFamily}
              align={titleTextAlign}
              height={titleLineHeight}
              color={titleColor}
              maxWidth={titleMaxWidth}
              withCols={titleWithCols}
              hightLight={titleColorHighlight}
            />
          </>
        )
      }
      else if (titleSize === 'H6') {
        return (
          <>
            {smallTitle && <Margin margin={2} />}
            <H6
              dangerouslySetInnerHTML={{ __html: title }}
              font={titleFontFamily}
              align={titleTextAlign}
              height={titleLineHeight}
              color={titleColor}
              maxWidth={titleMaxWidth}
              withCols={titleWithCols}
              hightLight={titleColorHighlight}
            />
          </>
        )
      }
      else {
        return null;
      }
    }
    return null;
  }

  const renderDescription = () => {
    if (description) {
      if (descriptionSize === 18) {
        return (
          <Margin margin={2}>
            <Text18
              dangerouslySetInnerHTML={{ __html: description }}
              font={descriptionFontFamily}
              align={descriptionTextAlign}
              height={descriptionLineHeight}
              color={descriptionColor}
              maxWidth={descriptionMaxWidth}
              withCols={descriptionWithCols}
            />
          </Margin>
        )
      }
      else if (descriptionSize === 16) {
        return (
          <Margin margin={2}>
            <Text16
              dangerouslySetInnerHTML={{ __html: description }}
              font={descriptionFontFamily}
              align={descriptionTextAlign}
              height={descriptionLineHeight}
              color={descriptionColor}
              maxWidth={descriptionMaxWidth}
              withCols={descriptionWithCols}
            />
          </Margin>
        )
      }
      else if (descriptionSize === 14) {
        return (
          <Margin margin={2}>
            <Text14
              dangerouslySetInnerHTML={{ __html: description }}
              font={descriptionFontFamily}
              align={descriptionTextAlign}
              height={descriptionLineHeight}
              color={descriptionColor}
              maxWidth={descriptionMaxWidth}
              withCols={descriptionWithCols}
            />
          </Margin>
        )
      }
      else if (descriptionSize === 12) {
        return (
          <Margin margin={2}>
            <Text12
              dangerouslySetInnerHTML={{ __html: description }}
              font={descriptionFontFamily}
              align={descriptionTextAlign}
              height={descriptionLineHeight}
              color={descriptionColor}
              maxWidth={descriptionMaxWidth}
              withCols={descriptionWithCols}
            />
          </Margin>
        )
      }
      else if (descriptionSize === 10) {
        return (
          <Margin margin={2}>
            <Text10
              dangerouslySetInnerHTML={{ __html: description }}
              font={descriptionFontFamily}
              align={descriptionTextAlign}
              height={descriptionLineHeight}
              color={descriptionColor}
              maxWidth={descriptionMaxWidth}
              withCols={descriptionWithCols}
            />
          </Margin>
        )
      }
      return null
    }
    return null
  }

  return (
    <Block center={centerContainer}>
      {renderAntTitle()}
      {renderTitle()}
      {renderDescription()}
    </Block>
  )
}

const Block = styled.div`
  display:flex;
  flex-flow:column;
  justify-content:center;
  ${({ center }) => center && css`
    align-items:center;
  `}
`;
const Margin = styled.div`
  margin-top: ${({ margin }) => margin ? margin * 8 : 8}px;
`;