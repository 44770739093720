import React from 'react';
import styled from 'styled-components';
import { Container, Section, Text12, Text18, Wrapper } from '../../atoms';

export default function ContentContactsMap({ linkMap, imgMap}) {

  return (
    <DivColumn>
      <Mapa href={linkMap} target="__blank">
        <img src={imgMap?.url}/>
      </Mapa>
    </DivColumn>
  )
}


const DivColumn = styled.div`
  display: flex;
  flex-flow: column;
`;
const Mapa = styled.a`
  img{
    width: 100%;
    height: 572px;
    object-fit: cover;
  }
`;