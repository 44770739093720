import { gql, useLazyQuery } from "@apollo/client";
import React, { useContext, useEffect, useState } from "react";
import { Route, Switch } from "react-router-dom";
import styled from 'styled-components';
import { getLanguage } from "../../../utils/language";
import { store } from "../../../utils/store";
import { Loading, ModalCookies } from "../../molecules";
import {
  MainComponent, Noticia,
  Page404,
  Page500, Produto,
  SubProduto
} from "../../templates";

export default function Main() {

  let lang = getLanguage();

  const { setDefaultLanguage, defaultLanguage, setLanguages, setNavbar, setMenu, setMedidataMundo, setGlobal } = useContext(store);

  const [loading, setLoading] = useState(false);

  const [getSettings] = useLazyQuery(
    gql`
      query {
        languages: frontendLanguages{
          id
          name
          code
          default
        }
        navbar: frontendContentType(key: "navbar"){
          content
        }
        menu: frontendContentType(key: "menu"){
          content
        }
        medidataMundo: frontendContentType(key: "medidataMundoNum"){
          content
        }
        general: frontendContentType(key: "global"){
          content
        }
      }
    `,
    {
      fetchPolicy: 'network-only',
      onError: (error) => { console.log(error) },
      onCompleted: (data) => {
        if (data?.languages && data?.languages?.length > 0) {
          setLanguages(data.languages);
          for (let i = 0; i < data.languages.length; i++) {
            if (data.languages[i].default === true) {
              setDefaultLanguage(data.languages[i].code);
            }
          }
        }
        if (data?.navbar?.content?.navbar) {
          setNavbar(data?.navbar?.content?.navbar);
        }
        if (data?.menu?.content?.sectionkey) {
          setMenu(data?.menu?.content?.sectionkey)
        }
        if (data?.general?.content?.sectionkey) {
          setGlobal(data?.general?.content?.sectionkey)
        }
        if (data?.medidataMundo?.content?.medidataMundo) {
          setMedidataMundo(data?.medidataMundo?.content?.medidataMundo)
        }
        setLoading(false);
      }
    },
    
  );

  useEffect(() => {
    getSettings();
    setLoading(true);
  }, [lang]);

  if (loading && defaultLanguage !== '') {
    return (<Loading lottie={true} isLoading={loading} />)
  } else {
    return (
      <Wrapper>
        <ModalCookies />
        <Switch>
          <Route exact path="/:language/404" component={Page404} />
          <Route exact path="/:language/500" component={Page500} />
          <Route exact path="/:language/produtos/:produto" component={Produto} />
          <Route exact path="/:language/produtos/:produto/aplicacao/:aplicacao" component={SubProduto} />
          <Route exact path="/:language/noticias/:noticia" component={Noticia} />
          <Route path="/:language?/:slug?" component={MainComponent} />
        </Switch>
      </Wrapper >
    )
  }

}

const Wrapper = styled.div`
  display: flex;
  flex-flow: column;
  position: relative;
  flex: 1;
  min-height: 100vh;
`;