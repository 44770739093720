import { InMemoryCache } from 'apollo-cache-inmemory';
import { ApolloClient } from 'apollo-client';
import { setContext } from 'apollo-link-context';
import { createHttpLink } from 'apollo-link-http';
import { getLanguage } from './language';

const httpLink = createHttpLink({
    uri: process.env.REACT_APP_BACKEND_HOST + '/graphql',
});

const authLink = setContext((_, { headers }) => {
    const language = getLanguage();

    return {
        headers: {
            ...headers,
            ...language && language !== null && { Language: language }
        }
    }
});

const client = new ApolloClient({
    link: authLink.concat(httpLink),
    cache: new InMemoryCache()
});


export default client;