import React, { createContext, useState } from 'react';

const store = createContext();
const { Provider } = store;

const StateProvider = ({ children }) => {
  const [lang, setLang] = useState('');
  const [defaultLanguage, setDefaultLanguage] = useState('');
  const [languages, setLanguages] = useState(undefined);
  const [navbar, setNavbar] = useState({});
  const [menu, setMenu] = useState({});
  const [medidataMundo, setMedidataMundo] = useState({});
  const [global, setGlobal] = useState({});
  return <Provider value={
    {
      lang, setLang,
      defaultLanguage, setDefaultLanguage,
      languages, setLanguages,
      navbar, setNavbar,
      menu, setMenu,
      medidataMundo, setMedidataMundo,
      global, setGlobal
    }
  }>{children}</Provider>;
};

export { store, StateProvider };
