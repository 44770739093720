import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { Button, Container, H2, H5, H6, Section, Text16, Text18, Wrapper } from '../../atoms';
import { Intro } from '../../molecules';
import { ReactSVG } from 'react-svg'
import { Link } from 'react-router-dom';
import { gql, useLazyQuery } from "@apollo/client";

export default function ProductsSectionHome({ buttonLink, buttonText, description, language, smallTitle, textHand, title }) {

  const [arrProducts, setArrProducts] = useState([]);

  const [getProducts, { loading }] = useLazyQuery(
    gql`
        query frontendProducts($first: Int){
          frontendProducts(first: $first){
            edges{
              node{
                id
                slug
                name
                description
                areas {
                  id
                }
              }
            }
          }
        }
    `,
    {
      fetchPolicy: 'network-only',
      onError: (error) => { console.log(error) },
      onCompleted: (data) => {
        if (data?.frontendProducts?.edges && data?.frontendProducts?.edges?.length > 0) {
          const newArray = data.frontendProducts.edges.map(x => ({
            content: x.node,
          })
          );
          setArrProducts(newArray);
        }
      }
    }
  );

  useEffect(() => {
    getProducts({ variables: { first: 6 } });
  }, []);

  const productsArr = window.innerWidth < 1000 ? arrProducts.slice(0,3) : arrProducts.slice(0,6);

  const renderTitle = (title) => {

    let temp = title.split('Sigma');
    let tempString = `Sigma<em>${temp[1] ? temp[1] : ''}</em>`;

    return (
      <TitleItem color="#35383B" dangerouslySetInnerHTML={{ __html: temp.length > 1 ? tempString : title }} />
    )
  }

  if (arrProducts && arrProducts?.length > 0) {
    return (
      <Section backgroundColor="#fff" >
        <Wrapper both noHeight >
          <Container md>
            <DivRowSpace>
              <DivContainer>
                <Intro
                  smallTitleColor="#368D67"
                  smallTitle={smallTitle}
                  titleSize="H3"
                  title={title}
                  titleColor="#35383B"
                />
                <TitleKnowHow color="#368D67" style={{ fontSize: 56 }} dangerouslySetInnerHTML={{ __html: textHand }} />
                <Margin margin={2.5} />
                <Text18 color="#B2B6B9" maxWidth="470px" dangerouslySetInnerHTML={{ __html: description }} />
              </DivContainer>
              <Button text={buttonText} link={'/' + language + buttonLink} color="368D67" />
            </DivRowSpace>
            <ContainerProducts>
              {(productsArr && productsArr?.length > 0) && productsArr?.map((product, index) => (
                <Item key={String(index)}>
                  {renderTitle(product.content?.name)}
                  <Margin />
                  <Text16 color="#B2B6B9" dangerouslySetInnerHTML={{ __html: (product?.content?.description && product?.content?.description.length > 116) ? product?.content?.description.slice(0, 116) + "..." : product?.content?.description }} />
                  <ButtonProduct to={'produtos' + product?.content?.slug}>
                    <IconPlus src="/static/images/icon_plus.svg" />
                  </ButtonProduct>
                </Item>
              ))}
            </ContainerProducts>
          </Container>
        </Wrapper>
      </Section>
    )
  } else {
    return null
  }

}

const Margin = styled.div`
  margin-top: ${({ margin }) => margin ? margin * 8 : 8}px;
`;
const DivContainer = styled.div`
  display: flex;
  flex-flow: column;
  height: 100%;
  margin-bottom: 72px;
  @media screen and (max-width: 760px){
    margin-bottom: 24px;
  }
`;
const DivRowSpace = styled.div`
  display: flex;
  flex-flow: row;
  justify-content: space-between;
  @media screen and (max-width: 760px){
    flex-flow: column;
    margin-bottom: 40px;
  }
`;
const TitleKnowHow = styled(H2)`
  font-family: 'Rockness', sans-serif;
`;
const ContainerProducts = styled.div`
  display: flex;
  flex-flow: row;
  flex-wrap: wrap;
  margin: -10px;
`;
const Item = styled.div`
  background: #FFFFFF;
  box-shadow: 0px 0px 16px rgba(0, 0, 0, 0.12);
  border-radius: 2px;
  width: calc(33% - 20px);
  max-width: 372px;
  height: 248px;
  padding: 32px 24px;
  box-sizing: border-box;
  margin: 10px;
  display: flex;
  flex-flow: column;
  position: relative;
  :nth-child(2){
    margin-top: -20px;
  }
  :nth-child(5){
    margin-top: -20px;
  }
  :nth-child(8){
    margin-top: -20px;
  }
  @media screen and (max-width: 1024px){
    width: calc(50% - 20px);
    :nth-child(5){
      margin-top: 10px;
    }
    :nth-child(2){
      margin-top: -20px;
    }
    :nth-child(4){
      margin-top: -20px;
    }
    :nth-child(6){
      margin-top: -20px;
    }
    :nth-child(8){
      margin-top: -20px;
    }
    :nth-child(10){
      margin-top: -20px;
    }
    :nth-child(12){
      margin-top: -20px;
    }
  }
  @media screen and (max-width: 670px){
    margin-top: 10px !important;
    width: calc(100% - 20px);
  }
`;
const TitleItem = styled(H5)`
  line-height: 29px;
  em{
    font-style: normal;
    color: #368D67;
  }
`;
const IconPlus = styled(ReactSVG)`
  width: 16px;
  height: 16px;
  object-fit: contain;
  svg{
    path{
      transition: all 0.5s ease-in-out;
      stroke: #D8DADC;
    }
  }
`;
const ButtonProduct = styled(Link)`
  position: absolute;
  right: 16px;
  bottom: 16px;
  width: 48px;
  height: 48px;
  box-sizing: border-box;
  border-radius: 50%;
  border: 1px solid #D8DADC;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 0.4s ease-in-out;
  :hover{
    cursor: pointer;
    border-color: #5D6368;
    ${IconPlus}{
      svg{
        path{
          stroke: #5D6368;
        }
      }
    }
  }
`;