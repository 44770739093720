import React from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { Text14 } from '..';
import { Loading } from '../../molecules';

export default function Button({ text, link, color, loading, ...props }) {

  return (
    <>
      {(link && text) &&
        <Link to={link}>
          <ButtonDiv color={color ? color : '368D67'}>
            <svg>
              <rect x="0" y="0" fill="none" width="100%" height="100%" />
            </svg>
            <Text14 color={color ? '#' + color : "#368D67"} dangerouslySetInnerHTML={{ __html: text }} />
          </ButtonDiv>
        </Link>
      }
      {(!link && text) &&
        <ButtonDiv color={color ? color : '368D67'} {...props}>
          <svg>
            <rect x="0" y="0" fill="none" width="100%" height="100%" />
          </svg>
          {!loading &&
            <Text14 color={color ? '#' + color : "#368D67"} dangerouslySetInnerHTML={{ __html: text }} />
          }
          {loading &&
            <Loading small isLoading={loading} />
          }
        </ButtonDiv>
      }
    </>
  )
}


const ButtonDiv = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 2px;
  height: 52px;
  width: 176px;
  box-sizing: border-box;
  transition: all 0.5s ease-in-out;
  position: relative;
  svg {
    height: 52px;
    left: 0;
    position: absolute;
    top: 0; 
    width: 100%; 
  }
  rect {
    fill: none;
    stroke: ${({ color }) => color ? '#' + color : '#368D67'};
    stroke-width: 1;
    stroke-dasharray: 422, 0;
    transition: all 0.5s ease-in-out;
  }
  :hover{
    cursor: pointer;
    rect {
      stroke-width: 1;
      stroke-dasharray: 35, 310;
      stroke-dashoffset: 48;
      transition: all 1.35s cubic-bezier(0.19, 1, 0.22, 1);
    }
  }
`;