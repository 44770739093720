import React from 'react'
import { Container, Section, Wrapper } from '../../atoms'
import { Intro } from '../../molecules'

export default function BannerClientes({ smallTitle, title }) {
  return (
    <Section backgroundColor="#5BB990">
      <Container lg>
        <Wrapper bothInitial noHeight>
          <Intro
            smallTitleColor="#368D67"
            smallTitle={smallTitle}
            titleSize="H2"
            title={title}
            titleColor="#35383B"
            titleMaxWidth="666px"
          />
        </Wrapper>
      </Container>
    </Section>
  )
}
