import styled, { css } from 'styled-components';

const AntTitle = styled.div`
  font-family: ${({ font }) => font ? font : 'AeonikBlack'} , sans-serif;
  font-size: 16px;
  text-align: ${({ align }) => align ? align : 'left'};
  color: ${({ color }) => color ? color : '#fff'};
  text-transform: uppercase;
  ${({ maxWidth }) => maxWidth && css`
    max-width: ${maxWidth};
  `}
`;

export default AntTitle;