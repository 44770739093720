import { gql, useLazyQuery } from "@apollo/client";
import React, { useEffect, useState } from 'react';
import { Collapse } from 'react-collapse';
import Select from 'react-select';
import styled, { css } from 'styled-components';
import { Container, H4, Section, Text12, Text14, Text16, Text18, Wrapper } from '../../atoms';
import { SORTED as sorted } from "../../../utils/misc";

export default function ClientsSection({ labelAngola, labelCabo, labelCam, labelCim, labelFilter, labelGuineBissau, labelJunta, labelOther, labelPortugal, language, placeholderZone, zoneCent, zoneNort, zoneSul, zoneMadeira, zoneAzores }) {

  let initialArrCountry = [
    {
      id: 0,
      country: labelPortugal,
      value: "PORTUGAL"
    },
    {
      id: 1,
      country: labelAngola,
      value: "ANGOLA"
    },
    {
      id: 2,
      country: labelCabo,
      value: "CABO_VERDE"
    },
    {
      id: 3,
      country: labelGuineBissau,
      value: "GUINE_BISSAU"
    }
  ];

  const [arrCountry, setArrCountry] = useState(initialArrCountry);
  const [dataTabsFilter, setDataTabsFilter] = useState([]);
  const [country, setCountry] = useState(0);
  const [filterEntity, setFilterEntity] = useState(undefined);
  const [open, setOpen] = useState(false);
  const [region, setRegion] = useState('');
  const [clients, setClients] = useState([]);
  const [loading, setLoading] = useState(false);

  const [getClients, { loading: loadingClients }] = useLazyQuery(
    gql`
        query frontendClients{
          frontendClients{
            id
            name
            type {
              id
              name
              position
            }
            country
            region
            freguesias{
              id
              name
            }
          }
        }
    `,
    {
      fetchPolicy: 'network-only',
      onError: (error) => { console.log(error) },
      onCompleted: (data) => {
        if (data?.frontendClients && data?.frontendClients.length > 0) {
          const tempArr = [];
          const tempClients = data?.frontendClients.sort(function (a, b) {
            if (a.name < b.name) { return -1; }
            if (a.name > b.name) { return 1; }
            return 0;
          });
          for (let i = 0; i < tempClients.length; i++) {
            const client = tempClients[i];
            let countryIndex = tempArr.findIndex((country) => country.name === client.country);

            if (countryIndex === -1) {
              tempArr.push({ name: client.country, types: [] });
              countryIndex = tempArr.length - 1;
            }

            let typeIndex = tempArr[countryIndex].types.findIndex((type) => type.name === client.type.name);

            if (typeIndex === -1) {
              tempArr[countryIndex].types.push({ name: client.type.name, position: client.type.position, clients: [] });
              typeIndex = tempArr[countryIndex].types.length - 1;
            }

            tempArr[countryIndex].types[typeIndex].clients.push({ ...client });
          }
          let item_order = ["PORTUGAL", "ANGOLA", "CABO_VERDE", "GUINE_BISSAU"];
          tempArr.sort((a, b) => item_order.indexOf(a.name) - item_order.indexOf(b.name));

          let countrysActive = tempArr.map(obj => obj.name);

          const result = arrCountry.filter(country => countrysActive.includes(country.value));
          setArrCountry(result);
          setClients(tempArr);
        }
      }
    }
  );

  const onlyUnique = (value, index, self) => {
    return self.indexOf(value) === index;
  }

  useEffect(() => {
    getClients();
  }, []);

  const changeDrop = (block, index) => {
    if (block !== false) {
      setOpen({ block, index })
    } else {
      setOpen(false);
    }
  }

  if (typeof window === 'undefined') {
    global.window = {}
  }

  const chunkArray = (myArray, chunk_size) => {
    var index = 0;
    var arrayLength = myArray.length;
    var tempArray = [];
    var myChunk = false;

    for (index = 0; index < arrayLength; index += Math.ceil(chunk_size)) {
      myChunk = myArray.slice(index, index + Math.ceil(chunk_size));
      tempArray.push(myChunk);
    }

    return tempArray;
  }

  const [dataTabs] = useState([
    {
      value: '',
      label: placeholderZone
    },
    {
      value: 'NORTE',
      label: zoneNort
    },
    {
      value: 'CENTRO',
      label: zoneCent
    },
    {
      value: 'SUL',
      label: zoneSul
    },
    {
      value: 'Madeira',
      label: zoneMadeira
    },
    {
      value: 'Acores',
      label: zoneAzores
    }
  ]);

  useEffect(() => {
    if (clients.length > 0) {
      setLoading(true);
      let tempArr = [];
      setDataTabsFilter([]);
      setFilterEntity(0);
      setRegion('');
      let client = clients[country];
      if (client?.types && client?.types.length > 0) {
        for (let u = 0; u < client.types.length; u++) {
          tempArr.push({
            index: u,
            position: client.types[u].position,
            label: client.types[u].name,
            value: client.types[u].name
          })
        }
      }
      let filtredArr = tempArr.filter(onlyUnique).sort(function (a, b) {
        if (a.position < b.position) { return -1; }
        if (a.position > b.position) { return 1; }
        return 0;
      });

      setDataTabsFilter(filtredArr);
      setFilterEntity(filtredArr[0].value);
      setTimeout(() => {
        setLoading(false);
      }, 100);
    }
  }, [clients, country]);

  const filterRegion = (filter) => {
    return function (x) {
      if (filter) {
        return (
          x.region.toLowerCase().includes(filter.toLowerCase())
        );
      }
    }
  };

  const renderContent = (tempArr) => {

    let index = tempArr.types.findIndex((temp) => temp.name === filterEntity);
    const array = tempArr.types[index];

    let clientsRegion;
    if (clients[country].name === "PORTUGAL") {
      clientsRegion = region === '' ? array?.clients : array?.clients.filter(filterRegion(region));
    } else {
      clientsRegion = array?.clients;
    }
    let clientsSorted = clientsRegion && sorted(clientsRegion);

    const columns = window.innerWidth < 1000 ? 1 : 2;
    const dataPerBlock = clientsSorted && clientsSorted.length / columns;
    const dataDropChunked = clientsSorted && chunkArray(clientsSorted, dataPerBlock);

    return (
      <>
        {
          (dataDropChunked && dataDropChunked.length > 0) && dataDropChunked.map((arr, i) => (
            <DivColumnDrop key={String(i)}>
              {arr.map((value, index) => (
                <DivColumn key={String(index)}>
                  <DivName>
                    <Text18 color="#35383B" dangerouslySetInnerHTML={{ __html: value.name }} />
                    {(value.freguesias && value.freguesias.length > 0) &&
                      <ButtonOpen onClick={() => (open.block === i && open.index === index) ? changeDrop(false) : changeDrop(i, index)}>
                        <img src={(open.block === i && open.index === index) ? '/static/images/minus.svg' : '/static/images/plus.svg'} />
                      </ButtonOpen>
                    }
                  </DivName>
                  <Collapse isOpened={(open.block === i && open.index === index)}>
                    <DivContainerList>
                      {(value.freguesias && value.freguesias.length > 0) &&
                        <List>
                          {sorted(value.freguesias).map((point, z) => (
                            <li key={String(z)}>
                              <Text14 color="#5D6368" dangerouslySetInnerHTML={{ __html: point.name }} />
                            </li>
                          ))}
                        </List>
                      }
                    </DivContainerList>
                  </Collapse>
                </DivColumn>
              ))}
            </DivColumnDrop>
          ))
        }
      </>
    )
  }

  const changeCountry = (index) => {
    setCountry(index)
  }

  if (clients && clients.length > 0) {
    return (
      <Section style={{ flex: 1 }}>
        <Container lg>
          <Wrapper both noHeight>
            <DivRow>
              {(arrCountry && arrCountry.length > 0) && arrCountry.map((titles, index) => (
                <TitleCountry key={String(index)} onClick={() => changeCountry(index)} changecolor={index === country} dangerouslySetInnerHTML={{ __html: titles.country }} />
              ))}
            </DivRow>
            <Margin margin={4.5} />
            <DivRowElements>
              <DivFilters>
                {(dataTabsFilter && dataTabsFilter.length > 0) && dataTabsFilter.map((filter, index) => (
                  <TitleFilter key={String(index)} onClick={() => setFilterEntity(filter.value)} changecolor={filter.value === filterEntity} dangerouslySetInnerHTML={{ __html: filter.label }} />
                ))}
                {(dataTabsFilter && dataTabsFilter.length > 0 && !loading) &&
                  <Dropdown
                    size
                    onChange={(entity) => setFilterEntity(entity.value)}
                    defaultValue={dataTabsFilter[0]}
                    value={dataTabsFilter.filter((tab) => tab.value === filterEntity)}
                    classNamePrefix='dropdown'
                    options={dataTabsFilter}
                  />
                }
                {(clients && clients.length > 0 && clients[country].name === "PORTUGAL") &&
                  <>
                    <Margin margin={4} />
                    <Text12 style={{ letterSpacing: '0.1em', textTransform: 'uppercase' }} font="AeonikBlack" color="#5D6368" dangerouslySetInnerHTML={{ __html: labelFilter }} />
                    <Margin margin={2} />
                    <Dropdown
                      onChange={options => setRegion(options.value)}
                      placeholder={placeholderZone}
                      value={dataTabs[region]}
                      classNamePrefix='dropdown'
                      options={dataTabs}
                    />
                  </>
                }
              </DivFilters>
              <DivContent>
                {filterEntity && renderContent(clients[country])}
              </DivContent>
            </DivRowElements>
          </Wrapper>
        </Container>
      </Section>
    )
  }
  return null;

}

const Margin = styled.div`
  margin-top: ${({ margin }) => margin ? margin * 8 : 8}px;
`;
const DivRow = styled.div`
  display: flex;
  flex-flow: row;
`;
const TitleCountry = styled(H4)`
  margin-right: 32px;
  min-height: 48px;
  :last-of-type{
    margin-right: 0;
  }
  color: #B2B6B9;
  opacity: 0.5;
  transition: all 0.4s ease-in-out;
  :hover{
    cursor: pointer;
    color: #35383B;
  }
  ${({ changecolor }) => changecolor && css`
    opacity: 1;
    color: #35383B;
  `}
  @media screen and (max-width: 600px){
    margin-right: 16px;
    min-height: unset;
  } 
`;
const DivRowElements = styled.div`
  display: flex;
  flex-flow: row;
  @media screen and (max-width: 600px){
    flex-flow: column;
  } 
`;
const DivFilters = styled.div`
  display: flex;
  flex-flow: column;
  min-width: 176px;
  @media screen and (max-width: 600px){
    margin-bottom: 36px;
  } 
`;
const TitleFilter = styled(Text16)`
  margin-bottom: 8px;
  :last-of-type{
    margin-bottom: 0;
  }
  color: #B2B6B9;
  transition: all 0.4s ease-in-out;
  :hover{
    cursor: pointer;
  }
  ${({ changecolor }) => changecolor && css`
    color: #35383B;
  `}
  @media screen and (max-width: 600px){
    display: none;
  } 
`;
const Dropdown = styled(Select)`
  font-size: 14px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 17px;
  letter-spacing: normal;
  color: #35383B;
  border-radius: 0;
  width: 176px;
  height: 52px;
  .dropdown__control {
    padding: 0 16px;
    border: unset;
    box-shadow: 0 0 0 0px #000;
    background: #EBECED;
    border-radius: 2px;
    width: 176px;
    height: 52px;
    :hover {
      border-color: inherit !important;
    }
    .dropdown__value-container{
      padding: 0;
    }
  }
  .dropdown__indicator {
    padding: 0px;
    width: 15px;
    color: #B2B6B9;
  }
  .dropdown__indicator-separator {
    width: 0px;
  }
  .dropdown__placeholder,
  .dropdown__single-value {
    font-size: 14px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: 17px;
    letter-spacing: normal;
    color: #B2B6B9;
    border-radius: 0;
  }
  .dropdown__single-value {
    color: #35383B;
  }

  .dropdown__menu {
    width: 176px;
    background: #EBECED;
    margin: 0;
    margin-top: 4px;
    border-radius: 2px;
    border-color: #EBECED;
    .dropdown__option  {
      background-color: #EBECED;
      min-height: 32px;
    }
    .dropdown__option--is-selected{
      color: #35383B;
      background-color: rgba(91, 185, 144, 0.5);
    }
    .dropdown__option--is-focused{
      background: #5BB990;
    }
  }
  ${({ size }) => size && css`
    @media screen and (min-width: 601px){
      display: none;
    } 
    @media screen and (max-width: 600px){
      display: block;
      width: 100%;
      .dropdown__control {
        width: 100%;
      }
      .dropdown__menu {
        width: 100%;
      }
    } 
  `}
`;
const DivContent = styled.div`
  display: flex;
  flex-flow: row;
  flex-wrap: wrap;
  margin-left: 20px;
  margin-right: -20px;
  width: 100%;
  @media screen and (max-width: 1000px){
    margin-right: 0;
  } 
  @media screen and (max-width: 600px){
    margin-left: 0;
  } 
`;
const DivColumnDrop = styled.div`
  width: calc(50% - 10px);
  margin-right: 20px;
  border-top: 1px solid #D8DADC;
  :last-of-type{
    margin-right: 0;
  }
  @media screen and (max-width: 1000px){
    margin-right: 0;
    width: 100%;
  } 
`;
const DivColumn = styled.div`
  display: flex;
  flex-flow: column;
  box-sizing: border-box;
  width: 100%;
  border-bottom: 1px solid #D8DADC;
  .ReactCollapse--collapse {
    transition: height 500ms;
  }
`;
const DivName = styled.div`
  padding: 32px 8px;
  display: flex;
  flex-flow: row;
  align-items: center;
  justify-content: space-between;
`;
const ButtonOpen = styled.div`
  width: 20px;
  height: 20px;
  :hover{
    cursor: pointer;
  }
  img{
    height: 100%;
    width: 100%;
    object-fit: contain;
  }
`;
const DivContainerList = styled.div`
  width: 100%;
  box-sizing: border-box;
  background-color: #F9FAFA;
  transition: all 0.4s ease-in-out;
  border-top: 1px solid #D8DADC;
  box-sizing: border-box;
  padding-left: calc(25px + (40 - 25) * ((100vw - 375px) / (1920 - 375)));
  padding-top: 12px;
  padding-bottom: 20px;
`;
const List = styled.ul`
  list-style: none;
  padding: 0;
  margin: 0;
  li { 
    display: flex;
    flex-flow: row; 
    margin-bottom: 16px;
  }
  li::last-child { 
    margin-bottom: 0;
  }
  li::before {
    content: "•";
    padding-top: 2px;
    padding-right: 8px;
    color: #B2B6B9;
  }
`;