import React from 'react';
import styled from 'styled-components';
import { Button, Container, Section, Wrapper } from '../../atoms';
import { Intro } from '../../molecules';

export default function ContactsSection({ language, smallTitle, title, buttonText, buttonLink }) {

  return (
    <Section backgroundColor="#fff" onTop>
      <Wrapper both noHeight >
        <Container lg>
          <DivContainer>
            <DivIntro>
              <Intro
                centerContainer
                smallTitleColor="#368D67"
                smallTitle={smallTitle}
                titleSize="H5"
                title={title}
                titleTextAlign="center"
                titleColor="#35383B"
                titleMaxWidth="372px"
              />
              <Margin margin={15} />
              <Button
                text={buttonText}
                link={'/' + language + buttonLink}
              />
            </DivIntro>
            <DivImage>
              <img src='/static/images/3d.png'/>
            </DivImage>
          </DivContainer>
        </Container>
      </Wrapper>
    </Section>
  )
}

const Margin = styled.div`
  margin-top: ${({ margin }) => margin ? margin * 8 : 8}px;
`;
const DivContainer = styled.div`
  display: flex;
  flex-flow: row;
  height: 100%;
  align-items: center;
  justify-content: center;
  position: relative;
  overflow: hidden;
`;
const DivIntro = styled.div`
  display: flex;
  min-height: 100%;
  flex-flow: column;
  justify-content: center;
  align-items: center;
  z-index: 1;
  @media screen and (max-width:700px){
    margin-bottom: 120px
  }
`;
const DivImage = styled.div`
  display: flex;
  flex-flow: row;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 52px;
  left: 0;
  iframe{
    width: 100%;
    height: 100%;
  }
  img {
    transition: all 0.4s ease-in-out;
    transform-style: preserve-3d;
    max-width: 100%;
  }
`