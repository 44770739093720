import React, { useEffect, useRef, useState } from 'react';
import styled, { css } from 'styled-components';
import { Container, H4, Modal, Text12, Wrapper } from '../../atoms';
import Intro from '../intros';
import { useI18Zen } from '@keegpt/i18zen';

export default function ModalForm(props) {

  const { __ } = useI18Zen();

  const {
    isOpen,
    children,
    toggleModal
  } = props;

  const [animation, setAnimation] = useState(false);

  const afterOpenModal = () => {
    setAnimation(true);
  };

  const afterCloseModal = () => {
    setAnimation(false);
  };

  const onRequestClose = () => {
    setAnimation(false);
  };

  useEffect(() => {
    if (isOpen === false) {
      onRequestClose();
    }
  }, [isOpen]);

  const [inputs, setInputs] = useState({
    name: '',
    photo: '',
    email: '',
    clinic: '',
    order: '',
    university: '',
    year: '',
    copyCC: '',
    copyOrder: '',
    copyInsurance: '',
    iban: ''
  })

  const handleInput = (event) => {
    event.persist();
    setInputs(inputs => ({ ...inputs, [event.target.name]: event.target.value }))
  };

  const hiddenPhotoInput = useRef(null);
  const hiddenCCInput = useRef(null);
  const hiddenOrdemInput = useRef(null);
  const hiddenSeguroInput = useRef(null);

  const handleUpload = (input) => {
    if (input && input === "photo") {
      hiddenPhotoInput.current.click();
    } else if (input && input === "copyCC") {
      hiddenCCInput.current.click();
    } else if (input && input === "copyOrder") {
      hiddenOrdemInput.current.click();
    } else if (input && input === "copyInsurance") {
      hiddenSeguroInput.current.click();
    }
  };

  const handleChange = (event, input) => {
    const fileUploaded = event.target.files;
    if (input && input === "photo") {
      setInputs(inputs => ({ ...inputs, photo: fileUploaded[0] }));
    } else if (input && input === "copyCC") {
      setInputs(inputs => ({ ...inputs, copyCC: fileUploaded[0] }));
    } else if (input && input === "copyOrder") {
      setInputs(inputs => ({ ...inputs, copyOrder: fileUploaded[0] }));
    } else if (input && input === "copyInsurance") {
      setInputs(inputs => ({ ...inputs, copyInsurance: fileUploaded[0] }));
    }
  };

  return (
    <Modal isOpen={isOpen} afterOpenModal={afterOpenModal} afterCloseModal={afterCloseModal}>
      <Overlay animation={animation} />
      <Content animation={animation}>
        <Structure>
          <Logo src={props && props.medicContent && props.medicContent.content && props.medicContent.content.logo} />
          <ButtonModal onClick={() => toggleModal()}>
            <Icon src="/static/images/menu_close.svg" />
          </ButtonModal>
          <Container lg>
            <Wrapper full both>
              <DivContent>
                <DivLeft>
                  <Image src="/static/images/imagem_registo.svg" />
                  <Intro
                    titleSize="H4"
                    title={__('titulo')}
                    titleColor="#008FA6"
                    titleMaxWidth="330px"
                    description={__('descricao')}
                    descriptionSize={12}
                    descriptionMaxWidth="237px"
                    descriptionColor="#9AA1AC"
                    descriptionLineHeight={14}
                  />
                </DivLeft>
                <DivRight>
                  <Form onSubmit={(event) => event.preventDefault()}>
                    <DivRow>
                      <DivColumn margin={24}>
                        <Text12 color="#3B3F45" height={24} dangerouslySetInnerHTML={{ __html: __("nomeLabel") }} />
                        <Input type="text" width={448} placeholder={__("nomePlaceholder")} value={inputs.name} name="name" onChange={handleInput} required />
                      </DivColumn>
                      <DivColumn>
                        <Text12 color="#3B3F45" height={24} dangerouslySetInnerHTML={{ __html: __("fotoLabel") }} />
                        <DivInput>
                          <Input type="text" value={inputs.photo && inputs.photo.name} width={236} style={{ pointerEvents: 'none' }} placeholder={__("fotoPlaceholder")} />
                          <input
                            type="file"
                            ref={hiddenPhotoInput}
                            onChange={(event) => handleChange(event, 'photo')}
                            style={{ display: 'none' }}
                          />
                          <IconInput onClick={() => handleUpload('photo')}>
                            <img src="/static/images/icon_download.svg" />
                          </IconInput>
                        </DivInput>
                      </DivColumn>
                    </DivRow>
                    <DivRow>
                      <DivColumn>
                        <Text12 color="#3B3F45" height={24} dangerouslySetInnerHTML={{ __html: __("emailLabel") }} />
                        <Input type="email" placeholder={__("emailPlaceholder")} value={inputs.email} name="email" onChange={handleInput} required />
                      </DivColumn>
                      <DivColumn margin={24}>
                        <Text12 color="#3B3F45" height={24} dangerouslySetInnerHTML={{ __html: __("moradaLabel") }} />
                        <Input type="email" placeholder={__("moradaPlaceholder")} value={inputs.morada} name="morada" onChange={handleInput} required />
                      </DivColumn>
                    </DivRow>
                    <DivRow>
                      <DivColumn margin={24}>
                        <Text12 color="#3B3F45" height={24} dangerouslySetInnerHTML={{ __html: __("nomeCliniLabel") }} />
                        <Input type="text" placeholder={__("nomeCliniPlaceholder")} value={inputs.clinic} name="clinic" onChange={handleInput} required />
                      </DivColumn>
                      <DivColumn >
                        <Text12 color="#3B3F45" height={24} dangerouslySetInnerHTML={{ __html: __("nIscricaoLabel") }} />
                        <Input type="text" width={252} placeholder={__("nIscricaoPlaceholder")} value={inputs.order} name="order" onChange={handleInput} required />
                      </DivColumn>
                    </DivRow>
                    <DivRow>
                      <DivColumn margin={24}>
                        <Text12 color="#3B3F45" height={24} dangerouslySetInnerHTML={{ __html: __("univLabel") }} />
                        <Input type="text" placeholder={__("univPlaceholder")} value={inputs.university} name="university" onChange={handleInput} required />
                      </DivColumn>
                      <DivColumn >
                        <Text12 color="#3B3F45" height={24} dangerouslySetInnerHTML={{ __html: __("anoLabel") }} />
                        <Input type="number" width={118} placeholder={__("anoPlaceholder")} value={inputs.year} name="year" min="1" max="9999" onChange={handleInput} required />
                      </DivColumn>
                    </DivRow>
                    <DivRow>
                      <DivColumn margin={24}>
                        <Text12 color="#3B3F45" height={24} dangerouslySetInnerHTML={{ __html: __("ccLabel") }} />
                        <DivInput>
                          <Input type="text" value={inputs.copyCC && inputs.copyCC.name} width={236} style={{ pointerEvents: 'none' }} placeholder={__("ccPlaceholder")} />
                          <input
                            type="file"
                            ref={hiddenCCInput}
                            onChange={(event) => handleChange(event, 'copyCC')}
                            style={{ display: 'none' }}
                          />
                          <IconInput onClick={() => handleUpload('copyCC')}>
                            <img src="/static/images/icon_download.svg" />
                          </IconInput>
                        </DivInput>
                      </DivColumn>

                      <DivColumn margin={24}>
                        <Text12 color="#3B3F45" height={24} dangerouslySetInnerHTML={{ __html: __("cOrdemLabel") }} />
                        <DivInput>
                          <Input type="text" value={inputs.copyOrder && inputs.copyOrder.name} width={236} style={{ pointerEvents: 'none' }} placeholder={__("cOrdemPlaceholder")} />
                          <input
                            type="file"
                            ref={hiddenOrdemInput}
                            onChange={(event) => handleChange(event, 'copyOrder')}
                            style={{ display: 'none' }}
                          />
                          <IconInput onClick={() => handleUpload('copyOrder')}>
                            <img src="/static/images/icon_download.svg" />
                          </IconInput>
                        </DivInput>
                      </DivColumn>
                      <DivColumn>
                        <Text12 color="#3B3F45" height={24} dangerouslySetInnerHTML={{ __html: __("apoliceLabel") }} />
                        <DivInput>
                          <Input type="text" value={inputs.copyInsurance && inputs.copyInsurance.name} width={236} style={{ pointerEvents: 'none' }} placeholder={__("apolicePlaceholder")} />
                          <input
                            type="file"
                            ref={hiddenSeguroInput}
                            onChange={(event) => handleChange(event, 'copyInsurance')}
                            style={{ display: 'none' }}
                          />
                          <IconInput onClick={() => handleUpload('copyInsurance')}>
                            <img src="/static/images/icon_download.svg" />
                          </IconInput>
                        </DivInput>
                      </DivColumn>
                    </DivRow>
                    <DivRow>
                      <DivColumn >
                        <Text12 color="#3B3F45" height={24} dangerouslySetInnerHTML={{ __html: __("ibanLabel") }} />
                        <Input type="text" placeholder={__("ibanPlaceholder")} value={inputs.iban} name="iban" onChange={handleInput} required />
                      </DivColumn>
                    </DivRow>
                    <DivRow>
                      <DivColumn>
                        <Text12 color="#9AA1AC" style={{ marginBottom: 4 }} height={20} dangerouslySetInnerHTML={{ __html: __("obrigatorios") }} />
                        <Text12 color="#9AA1AC" height={20} dangerouslySetInnerHTML={{ __html: __("roupa") }} />
                      </DivColumn>
                    </DivRow>
                    <ButtonSubmit type="submit" value={__('buttonText')}/>
                  </Form>
                </DivRight>
              </DivContent>
            </Wrapper>
          </Container>
          {children}
        </Structure>
      </Content>
    </Modal>
  )
}
const Margin = styled.div`
  margin-top: ${({ margin }) => margin ? margin * 8 : 8}px;
`
const Overlay = styled.div`
  position: fixed;
  left: 0;
  right: 0;
  top: 72px;
  bottom: 0;
  background-color: rgba(0,0,0,0);
  transition: all 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94);
  ${({ animation }) => animation && css`
    background-color: rgba(0,0,0,0);
  `}
`;
const Structure = styled.div`
  width: 100%;
  min-height: 100%;
  display: flex;
  flex-flow:column;
  box-sizing: border-box;
  background: #fff;
  transition: all 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94);
  transform: translateX(-100%);
`;
const Content = styled.div`
  position: relative;
  z-index: 2;
  width: 100%;
  min-height: 100%;
  display: flex;
  opacity: 0;
  transition: all 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94);
  ${({ animation }) => animation && css`
    opacity: 1;
    ${Structure} {
      transform: translateX(0%);
    }
  `};
  @media screen and (max-width: 1200px){
    width:100%;
  }
`;
const ButtonModal = styled.div`
  position: absolute;
  top: 24px;
  right: 24px;
  display: flex;
  flex-flow: row;
  align-items: center;
  justify-content: center;
  background: #77EDA6;
  border-radius: 6px;
  padding: 12px;
  transition: 0.4s ease-in-out;
  z-index: 1;
  :hover{
    cursor: pointer;
    background: #008FA6
  }
`;
const Logo = styled.img`
  position: absolute;
  top:24px;
  left:24px;
  max-height: 32px;
  object-fit: contain;
`;
const Icon = styled.img`
  height: 16px;
  width: 16px;
  max-height: 16px;
  max-width: 16px;
  object-fit: contain;
`;
const DivContent = styled.div`
  display:flex;
  flex-flow: row;
  width: 100%;
  height: 100%;
  justify-content: center;
  @media screen and (max-width: 1200px){
    flex-flow: column;
  }
`;
const DivLeft = styled.div`
  display: flex;
  flex-flow: column;
  margin-right: 64px;
  @media screen and (max-width: 1200px){
    margin-right: 0;
    margin-bottom: 64px;
  }
`;
const DivRight = styled.div`
  display: flex;
  flex-flow: column;
`;
const Image = styled.img`
  max-width: 212px;
  margin-bottom: 30px;
`;
const Form = styled.form`
  display: flex;
  flex-flow: column;
`;
const DivColumn = styled.div`
  display: flex;
  flex-flow: column;
  margin-right: ${({ margin }) => margin ? margin : 0}px;
  @media screen and (max-width: 600px){
    margin: 0;
    margin-bottom: 8px;
  }
`;
const DivRow = styled.div`
  display: flex;
  flex-flow: row;
  margin-bottom: 16px;
  :last-of-type{
    margin-bottom: 0;
  }
  @media screen and (max-width: 600px){
    flex-flow: column;
  }
`;
const Input = styled.input`
  margin-top: 8px;
  width: ${({ width }) => width ? width : '330'}px;;
  height: 48px;
  box-sizing: border-box;
  border-radius: 6px;
  display: flex;
  align-items: center;
  padding: 12px;
  background: #F2F6FA;
  border: 1px solid #EBEEF2;
  font-family: 'SFProTextSemibold',sans-serif;
  font-size: 14px;
  line-height: 16px;
  color: #828282;
  transition: all 0.4s ease-in-out;
  appearance: none;
  max-width: 100%;
  ${({ half }) => half && css`
    width: 100%;
  `}
  :hover{
    border: 1px solid #77EDA6;
  }
  :focus{
    border: 1px solid #008FA6;
  }
  ::placeholder{
    color: #9AA1AC;
  }
  @media screen and (max-width: 800px){
    max-width: 100%;
    ${({ half }) => half && css`
      width: 100%;
      max-width: 140px;
    `}
  }
  @media screen and (max-width: 600px){
    width: 100%;
  }
`;
const DivInput = styled.div`
  position: relative;
  display: flex;
  flex-flow: row;
  ${Input}{
    padding-right: 48px;
  }
`;
const IconInput = styled.div`    
  width: 20px;
  height: 20px;
  position: absolute;
  right: 18px;
  top: 8px;
  bottom: 0;
  height: calc(100% - 8px);
  display: flex;
  align-items: center;
  justify-content: center;
  > img {
    object-fit: contain;
  }
  :hover{
    cursor: pointer;
  }
`;
const ButtonSubmit = styled.input`
  margin-top: 20px;
  width: 212px;
  height: 40px;
  background: #77EDA6;
  border-radius: 6px;
  font-family: 'SFProTextSemibold',sans-serif;
  font-size: 14px;
  line-height: 17px;
  color: #FFFFFF;
  border: none;
  appearance: none;
  transition: all 0.4s ease-in-out;
  :hover{
    cursor: pointer;
    background: #008FA6;
  }
`;