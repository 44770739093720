import React, { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import styled, { css } from 'styled-components';
import { Container, H4, Section, Wrapper, Text16, Text12, H2, H6, H5 } from '../../atoms';
import moment from 'moment';
import { gql, useLazyQuery } from "@apollo/client";

export default function NewsDetail() {

  const [news, setNews] = useState([]);
  const [metatags, setMetatags] = useState(null);

  let history = useHistory();
  let { noticia } = useParams();

  const [getNews, { loading }] = useLazyQuery(
    gql`
        query frontendArticle($slug: String!){
          frontendArticle(slug: $slug){
            id
            thumbnail
            slug
            title
            excerpt
            date
            content
            metatags
          }
        }
    `,
    {
      fetchPolicy: 'network-only',
      onError: (error) => { console.log(error) },
      onCompleted: (data) => {
        if (data?.frontendArticle) {
          setNews(data?.frontendArticle);
          if (data.frontendArticle?.metatags) {
            setMetatags(data.frontendArticle.metatags)
          }
        }
      }
    }
  );

  useEffect(() => {
    if (noticia) {
      getNews({ variables: { slug: noticia } })
    }
  }, [noticia]);

  useEffect(() => {
    if (metatags !== null) {
      insertMetatags(metatags)
    }
  }, [metatags]);

  const insertMetatags = (meta) => {
    if (meta !== null) {

      const {
        title,
        description,
        keywords
      } = meta;

      if (title) {
        document.getElementById("ogtitle").setAttribute("content", title);
      }
      if (description) {
        document.getElementById("ogdescription").setAttribute("content", description);
      }
      if (keywords) {
        document.getElementById("keywords").setAttribute("content", keywords.toString());
      }
    }
  }

  const [sliderIndex, setSliderIndex] = useState(0);

  return (
    <Section noFade>
      <GoBack onClick={() => history.goBack()}>
        <img src="/static/images/icon_close.svg" />
      </GoBack>
      <Wrapper bothInitial noHeight>
        <Container lg>
          <H2 color="#35383B" maxWidth="960px" dangerouslySetInnerHTML={{ __html: news?.title }} />
          <Margin margin={3} />
          <Text12 color="#5D6368" dangerouslySetInnerHTML={{ __html: moment(news?.date).format('DD.MM.YYYY') }} />
          <Margin margin={2.5} />
          <H6 color="#B2B6B9" maxWidth="960px" dangerouslySetInnerHTML={{ __html: news?.content?.sectionkey?.description }} />
          {(news?.content?.sectionkey?.list && news?.content?.sectionkey?.list.length > 0) &&
            <Margin margin={6.5}>
              <DivRowSlider>
                {(news?.content?.sectionkey?.list && news?.content?.sectionkey?.list.length > 0) &&
                  <>
                    <ContainerSlider>
                      {news?.content?.sectionkey?.list.map((study, index) => (
                        <ImageItem key={String(index)} className={index === sliderIndex ? 'show' : ''} src={study.img.url} />
                      ))}
                    </ContainerSlider>
                    <DivColumnArrows>
                      <Numbers height={38} dangerouslySetInnerHTML={{ __html: '0' + (sliderIndex + 1) }} />
                      <Line />
                      <Numbers height={38} color="#B2B6B9" dangerouslySetInnerHTML={{ __html: '0' + (news?.content?.sectionkey?.list.length) }} />
                      <Margin margin={5} />
                      <ButtonNextPrev onClick={() => sliderIndex > 0 ? setSliderIndex(sliderIndex - 1) : null}>
                        <IconChevron src="/static/images/chevron.svg" />
                      </ButtonNextPrev>
                      <ButtonNextPrev onClick={() => sliderIndex >= news?.content?.sectionkey?.list.length - 1 ? null : setSliderIndex(sliderIndex + 1)}>
                        <IconChevron style={{ transform: 'rotate(180deg)' }} src="/static/images/chevron.svg" />
                      </ButtonNextPrev>
                    </DivColumnArrows>
                  </>
                }
              </DivRowSlider>
              <Margin margin={2} />
              <DivRow style={{ maxWidth: 470, minHeight: 16 }}>
                {(news?.content?.sectionkey?.list && news?.content?.sectionkey?.list.length > 0) && news?.content?.sectionkey?.list.map((study, index) => (
                  <TextSlider key={String(index)} className={index === sliderIndex ? 'show' : ''} maxWidth="470px" dangerouslySetInnerHTML={{ __html: study.description }} />
                ))}
              </DivRow>
            </Margin>
          }
          {news?.content?.sectionkey?.secondTitle &&
            <Margin margin={9}>
              <H5 color="#35383B" maxWidth="764px" dangerouslySetInnerHTML={{ __html: news?.content?.sectionkey?.secondTitle }} />
            </Margin>
          }
          {news?.content?.sectionkey?.text &&
            <Margin margin={2}>
              <TextDescription height={24} color="#B2B6B9" maxWidth="764px" dangerouslySetInnerHTML={{ __html: news?.content?.sectionkey?.text }} />
            </Margin>
          }
          {news?.content?.sectionkey?.img?.url &&
            <Margin margin={6.5}>
              <Image src={news?.content?.sectionkey?.img?.url} />
            </Margin>
          }
          {news?.content?.sectionkey?.thirdTitle &&
            <Margin margin={6}>
              <H5 color="#35383B" maxWidth="764px" dangerouslySetInnerHTML={{ __html: news?.content?.sectionkey?.thirdTitle }} />
            </Margin>
          }
          {(news?.content?.sectionkey?.listFunc && news?.content?.sectionkey?.listFunc.length > 0) &&
            <Margin margin={2}>
              <List>
                {(news?.content?.sectionkey?.listFunc && news?.content?.sectionkey?.listFunc.length > 0) && news?.content?.sectionkey?.listFunc.map((funcionality, index) => (
                  <li key={String(index)}>
                    <Text16 color="#B2B6B9" maxWidth="764px" dangerouslySetInnerHTML={{ __html: funcionality.text }} />
                  </li>
                ))}
              </List>
            </Margin>
          }
        </Container>
      </Wrapper>
    </Section>
  )
}

const Margin = styled.div`
  margin-top: ${({ margin }) => margin ? margin * 8 : 8}px;
`;
const GoBack = styled.div`
  position: absolute;
  top: 30px;
  left: 30px;
  z-index:4;
  img{
    max-width: 24px;
    max-height: 20px;
    object-fit: contain;
  }
  :hover{
    cursor: pointer;
  }
`;
const DivRow = styled.div`
  position: relative;
  display: flex;
  flex-flow: row;
  @media screen and (max-width: 600px){
    flex-flow: column;
  }
`;
const DivRowSlider = styled.div`
  display: flex;
  flex-flow: row;
  max-width: 960px;
  @media screen and (max-width: 600px){
    flex-flow: column;
  }
`;
const ContainerSlider = styled.div`
  position: relative; 
  flex: 1;
  display: flex;
  flex-flow: row;
  justify-content: flex-end;
  align-items: flex-end;
  height: 528px;
  overflow: hidden;
  @media screen and (max-width: 600px){
    flex: unset;
    height: 320px;
  }
`;
const ImageItem = styled.img`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: contain;
  opacity: 0;
  transition: all 0.4s ease-in-out;
  &.show{
    opacity: 1;
  }
`;
const DivColumnArrows = styled.div`
  display: flex;
  flex-flow: column;
  padding: 0 28px;
  justify-content: flex-end;
  align-items: center;
  @media screen and (max-width:700px){
    flex-flow: row;
    padding: 28px 0;
  }
`;
const Numbers = styled(H4)`
  margin-bottom: 16px;
  :last-of-type{
    margin-bottom: 40px;
    margin-top: 16px;
  }
  @media screen and (max-width:700px){
    margin-bottom: 0;
    margin-right: 16px;
    :last-of-type{
      margin-bottom: 0;
      margin-top: 0;
      margin-right: 40px;
      margin-left: 16px;
    }
  }
`;
const ButtonNextPrev = styled.div`
  width: 52px;
  height: 52px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid #B2B6B9;
  border-radius: 26px;
  box-sizing: border-box;
  margin-bottom: 8px;
  :last-of-type{
    margin-bottom: 0;
  }
  :hover{
    cursor: pointer;
  }
  @media screen and (max-width:700px){
    margin-bottom: 0;
    margin-right: 8px;
    :last-of-type{
      margin-right: 0;
    }
  }
`;
const IconChevron = styled.img`
  width: 6px;
  height: 12px;
  object-fit: contain;
`;
const Line = styled.div`
  width: 30px;
  height: 1px;
  border-top: 1px solid #B2B6B9;
`;
const TextSlider = styled(Text12)`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  opacity: 0;
  transition: all 0.6s ease-in-out;
  transform: translateX(100%);
  &.show{
    opacity: 1;
    transform: translateX(0);
  }
`;
const TextDescription = styled(Text16)`
  p{
    margin-block-start: 1em;
    margin-block-end: 1em;
    margin-inline-start: 0px;
    margin-inline-end: 0px;
  }
  a{
    color: #368D67;
    text-decoration: underline;
  }
`;
const Image = styled.img`
  max-width: 764px;
  object-fit: contain;
  @media screen and (max-width:700px){
    max-width: 100%;
  }
`;
const List = styled.ul`
  list-style: none;
  padding: 0;
  margin: 0;
  li { 
    display: flex;
    flex-flow: row; 
    margin-bottom: 16px;
  }
  li::last-child { 
    margin-bottom: 0;
  }
  li::before {
    content: "•";
    padding-top: 2px;
    padding-right: 8px;
    color: #368D67;
  }
`;