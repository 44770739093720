import { gql, useLazyQuery } from "@apollo/client";
import React, { useEffect, useRef, useState } from 'react';
import { Link } from 'react-router-dom';
import Select from 'react-select';
import { ReactSVG } from 'react-svg';
import styled from 'styled-components';
import { Container, H2, H5, Section, Text16, Text18, Wrapper } from '../../atoms';
import { Intro, Loading } from '../../molecules';

export default function ProductsSection({ description, descriptionSigma, placeholderArea, placeholderProduct, smallTitle, textHand, title, noResults, titleSigma }) {

  const [arrProducts, setArrProducts] = useState([]);
  const [areas, setAreas] = useState([]);
  const [search, setSearch] = useState('');
  const [category, setCategory] = useState('');

  const [getAreas, { loadingAreas }] = useLazyQuery(
    gql`
        query frontendAreas{
          frontendAreas{
            id
            name
          }
        }
    `,
    {
      fetchPolicy: 'network-only',
      onError: (error) => { console.log(error) },
      onCompleted: (data) => {
        if (data?.frontendAreas && data?.frontendAreas?.length > 0) {
          const newArray = data.frontendAreas.map(x => ({
            value: x.id,
            label: x.name,
          })
          );
          newArray.unshift({
            value: '',
            label: 'Todos',
          })
          setAreas(newArray);
        }
      }
    }
  );

  const [getProducts, { loading }] = useLazyQuery(
    gql`
        query frontendProducts($first: Int, $search: String){
          frontendProducts(first: $first, search: $search){
            edges{
              node{
                id
                slug
                name
                description
                areas {
                  id
                }
              }
              cursor
            }
          }
        }
    `,
    {
      fetchPolicy: 'network-only',
      onError: (error) => { console.log(error) },
      onCompleted: (data) => {
        if (data?.frontendProducts?.edges && data?.frontendProducts?.edges?.length > 0) {
          const newArray = data.frontendProducts.edges.map(x => ({
            cursor: x.cursor,
            content: x.node,
          })
          );
          setArrProducts(newArray);
        }
      }
    }
  );

  useEffect(() => {
    getAreas();
    getProducts({ variables: { first: 1000 } });
  }, []);

  const filterProducts = (search) => {
    return function (x) {
      if (search) {
        return (
          x.content.name.toLowerCase().includes(search.toLowerCase())
        );
      }
    }
  }

  const filterProductsCategory = (category) => {
    return function (x) {
      if (category) {
        if (x.content && x.content.areas && x.content.areas.length > 0) {
          return (
            x.content.areas.find(area => area.id === category)
          )
        }
      }
    }
  }

  let productList = (search === '' || search.length < 3) ? arrProducts : arrProducts.filter(filterProducts(search));
  let productListFiltred = category === '' ? productList : productList.filter(filterProductsCategory(category));

  const renderTitle = (title) => {

    let temp = title.split('Sigma');
    let tempString = `Sigma<em>${temp[1]}</em>`;

    return (
      <TitleItem color="#35383B" dangerouslySetInnerHTML={{ __html: temp.length > 1 ? tempString : title }} />
    )
  }

  return (
    <Section backgroundColor="#fff" >
      <Wrapper bothInitial noHeight >
        <Container md>
          <DivContainer>
            <Intro
              smallTitleColor="#368D67"
              smallTitle={smallTitle}
              titleSize="H3"
              title={title}
              titleColor="#35383B"
            />
            <TitleKnowHow color="#368D67" dangerouslySetInnerHTML={{ __html: textHand }} />
            <Margin margin={2.5} />
            <Text18 color="#B2B6B9" maxWidth="470px" dangerouslySetInnerHTML={{ __html: description }} />
            <Margin margin={3.5} />
            <DivRow className='search'>
              <Form>
                <Input onChange={(e) => setSearch(e.target.value)} type="text" placeholder={placeholderProduct} />
                <ButtonSubmit>
                  <SearchIcon src="/static/images/icon_search.svg" />
                </ButtonSubmit>
              </Form>
              <Dropdown
                onChange={options => setCategory(options.value)}
                placeholder={placeholderArea}
                value={areas[category]}
                classNamePrefix='dropdown'
                options={areas}
              />
            </DivRow>
            <Margin margin={5.5} />
            <Intro
              titleSize="H6"
              title={titleSigma}
              titleColor="#35383B"
              description={descriptionSigma}
              descriptionSize={16}
              descriptionMaxWidth="470px"
              descriptionColor="#B2B6B9"
            />
          </DivContainer>
          <ContainerProducts>
            {loading &&
              <Loading isLoading={loading} />
            }
            {(!loading && productListFiltred && productListFiltred.length > 0) && productListFiltred.map((product, index) => (
              <Item key={String(index)}>
                {renderTitle(product.content?.name)}
                <Margin />
                <Text16 color="#B2B6B9" dangerouslySetInnerHTML={{ __html: (product.content?.description?.length > 116) ? product.content?.description?.slice(0, 116) + "..." : product.content?.description }} />
                <ButtonProduct to={'produtos' + product.content.slug}>
                  <IconPlus src="/static/images/icon_plus.svg" />
                </ButtonProduct>
              </Item>
            ))}
            {(!loading && productListFiltred && productListFiltred.length == 0) &&
              <H5 color='#35383B' dangerouslySetInnerHTML={{ __html: noResults }} />
            }
          </ContainerProducts>
        </Container>
      </Wrapper>
    </Section>
  )
}

const Margin = styled.div`
  margin-top: ${({ margin }) => margin ? margin * 8 : 8}px;
`;
const DivContainer = styled.div`
  display: flex;
  flex-flow: column;
  height: 100%;
  margin-bottom: 72px;
  @media screen and (max-width: 760px){
    margin-bottom: 24px;
  }
`;
const TitleKnowHow = styled(H2)`
  font-family: 'Rockness', sans-serif;
`;
const ContainerProducts = styled.div`
  display: flex;
  flex-flow: row;
  flex-wrap: wrap;
  margin: -10px;
  min-height: 80px;
  position: relative;
`;
const Item = styled.div`
  background: #FFFFFF;
  box-shadow: 0px 0px 16px rgba(0, 0, 0, 0.12);
  border-radius: 2px;
  width: calc(33% - 20px);
  max-width: 372px;
  height: 248px;
  padding: 32px 24px;
  box-sizing: border-box;
  margin: 10px;
  display: flex;
  flex-flow: column;
  position: relative;
  @media screen and (max-width: 1024px){
    width: calc(50% - 20px);
  }
  @media screen and (max-width: 670px){
    margin-top: 10px !important;
    width: calc(100% - 20px);
  }
`;
const TitleItem = styled(H5)`
  line-height: 29px;
  em{
    font-style: normal;
    color: #5BB990;
  }
`;
const IconPlus = styled(ReactSVG)`
  width: 16px;
  height: 16px;
  object-fit: contain;
  svg{
    path{
      transition: all 0.5s ease-in-out;
      stroke: #D8DADC;
    }
  }
`;
const ButtonProduct = styled(Link)`
  position: absolute;
  right: 16px;
  bottom: 16px;
  width: 48px;
  height: 48px;
  box-sizing: border-box;
  border-radius: 50%;
  border: 1px solid #D8DADC;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 0.4s ease-in-out;
  :hover{
    cursor: pointer;
    border-color: #5D6368;
    ${IconPlus}{
      svg{
        path{
          stroke: #5D6368;
        }
      }
    }
  }
`;
const DivRow = styled.div`
  display: flex;
  flex-flow: row;
  @media screen and (max-width: 760px){
    &.search{
      flex-flow: column;
    }
  }
`;
const Form = styled.div`
  display: flex;
  flex-flow: row;
  position: relative;
  max-width: 100%;
  @media screen and (max-width: 1024px){
    width: 350px;
  }
`;
const Input = styled.input`
  width: 568px;
  max-width: 100%;
  height: 52px;
  background: #EBECED;
  border-radius: 2px;
  max-width: 100%;
  padding: 12px 40px 12px 16px;
  transition: all 0.4s ease-in-out;
  color: #35383B;
  box-sizing: border-box;
  border: unset;
  margin-right: 20px;
  ::placeholder{
    color: #B2B6B9;
  }
  :focus{
    border-color: #B2B6B9;
  }
  :hover{
    cursor: pointer;
  }
  @media screen and (max-width: 1024px){
    width: 350px;
  }
  @media screen and (max-width: 760px){
    margin-right: 0;
    margin-bottom: 20px;
  }
`;
const SearchIcon = styled(ReactSVG)`
  svg{
    path{
      transition: all 0.5s ease-in-out;
      stroke: #B2B6B9;
    }
    circle{
      transition: all 0.5s ease-in-out;
      stroke: #B2B6B9;
    }
  }
`;
const ButtonSubmit = styled.div`
  position: absolute;
  top: 16px;
  right: 36px;
  padding: 0;
  margin: 0;
`;
const Dropdown = styled(Select)`
  font-size: 14px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 17px;
  letter-spacing: normal;
  color: #35383B;
  border-radius: 0;
  width: 274px;
  height: 52px;
  .dropdown__control {
    padding: 0 16px;
    border: unset;
    box-shadow: 0 0 0 0px #000;
    background: #EBECED;
    border-radius: 2px;
    width: 274px;
    height: 52px;
    :hover {
      border-color: inherit !important;
    }
    .dropdown__value-container{
      padding: 0;
    }
  }
  .dropdown__indicator {
    padding: 0px;
    width: 15px;
    color: #B2B6B9;
  }
  .dropdown__indicator-separator {
    width: 0px;
  }
  .dropdown__placeholder,
  .dropdown__single-value {
    font-size: 14px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: 17px;
    letter-spacing: normal;
    color: #B2B6B9;
    border-radius: 0;
  }
  .dropdown__single-value {
    color: #35383B;
  }

  .dropdown__menu {
    width: 274px;
    background: #EBECED;
    margin: 0;
    margin-top: 4px;
    border-radius: 2px;
    border-color: #EBECED;
    .dropdown__option  {
      background-color: #EBECED;
    }
    .dropdown__option--is-selected{
      color: #35383B;
      background-color: rgba(91, 185, 144, 0.5);
    }
    .dropdown__option--is-focused{
      background: #5BB990;
    }
  }
`;