import React, { useState } from 'react';
import styled, { css } from 'styled-components';
import { Container, H5, Section, Text14, Text16, Wrapper } from '../../atoms';
import { Waypoint } from 'react-waypoint';

export default function NossoCaminho({ textHand, floatText, arr }) {

  const [show, setShow] = useState(false);

  const element = (innerText, index) => {
    let value = index + 1;
    let delay = value * 1.2;
    let position = '';
    if (value % 2 == 0) {
      position = 'left';
    }
    if (value % 2 == 1) {
      position = 'right';
    }

    let last = (index + 1 === arr.length);

    return (
      <DivRowElement className={position} show={show} style={{ transitionDelay: (delay + 's'), marginBottom: last && 0 }}>
        <Point />
        <DivColumn>
          <TitleText color="#35383B" maxWidth="470px" font="AeonikBold" dangerouslySetInnerHTML={{ __html: innerText.title }} />
          <Text
            font="AeonikRegular"
            color="#B2B6B9"
            maxWidth="470px"
            dangerouslySetInnerHTML={{ __html: innerText.text }}
          />
        </DivColumn>
      </DivRowElement>
    )
  }

  return (
    <Section backgroundColor="#fff" noFade>
      <Container lg>
        <Wrapper both noHeight>
          <Title dangerouslySetInnerHTML={{ __html: textHand }} />
          <Margin margin={3.5} />
          <DivContainer>
            <DivLine>
              <DivFloatTitle>
                <Text14 color="#fff" dangerouslySetInnerHTML={{ __html: floatText }} />
              </DivFloatTitle>
              <img src="/static/images/linha.svg" />
            </DivLine>
            <Waypoint
              onEnter={() =>
                setShow(true)
              }
            >
              <DivColumnEle>
                {(arr && arr.length > 0) && arr.map((innerText, index) => (
                  <DivMain key={String(index)}>
                    {element(innerText, index)}
                  </DivMain>
                ))}
                <DivLastPoint>
                  <Point />
                </DivLastPoint>
              </DivColumnEle>
            </Waypoint>
          </DivContainer>
        </Wrapper>
      </Container>
    </Section>
  )
}

const Title = styled.div`
  font-family: 'Rockness', sans-serif;
  text-align: center;
  font-size: calc(50px + (200 - 50) * ((100vw - 375px) / (1920 - 375)));
  line-height: calc(64px + (292 - 64) * ((100vw - 375px) / (1920 - 375)));
  color: #368D67;
`;
const Margin = styled.div`
  margin-top: ${({ margin }) => margin ? margin * 8 : 8}px;
`;
const DivContainer = styled.div`
  display: flex;
  flex-flow: row;
  position: relative;
`;
const DivLine = styled.div`
  display: block;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  text-align: center;
  img{
    height: calc(100% - 44px);
  }
  @media screen and (max-width: 1100px){
    text-align: left;
    top: 12px;
    height: calc(100% - 12px);
    img{
      height: calc(100% - 10px);
    }
  }
`;
const DivFloatTitle = styled.div`
  position: sticky;
  top: 120px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 144px;
  height: 44px;
  background: #35383B;
  border-radius: 22px;
  margin: 0 auto;
  z-index: 2;
  @media screen and (max-width: 1100px){
    margin:0;
    display: none;
  }
`;
const DivColumnEle = styled.div`
  display: flex;
  flex-flow: column;
  width: 100%;
  z-index: 1;
`;
const DivMain = styled.div`
  @media screen and (max-width: 1100px){
    margin-bottom: 24px;
    :last-child{
      margin-bottom: 0;
    }
  }
`;
const DivRowElement = styled.div`
  display: flex;
  flex-flow: row;
  justify-content: space-between;
  width: calc(50% + 4px);
  margin-bottom: calc(60px + (120 - 60) * ((100vw - 375px) / (1920 - 375)));
  :first-child{
    padding-top: calc(60px + (180 - 60) * ((100vw - 375px) / (1920 - 375)));
  }
  :last-of-type{
    margin-bottom: 0;
  }
  transition: all 0.5s ease-in-out;
  opacity: 0;
  &.left{
    margin-left: auto;
    ${({ show }) => show && css`
      opacity: 1;
    `};
  }
  &.right{
    margin-right: auto;
    flex-flow: row-reverse;
    ${({ show }) => show && css`
      opacity: 1;
    `};
  }
  @media screen and (max-width: 1100px){
    width: 100%;
    max-width: calc(100% - 4px);
    justify-content: unset;
    margin-bottom: 24px;
    :first-child{
      padding-top: 0;
    }
    :last-child{
      margin-bottom: 0;
    }
    &.left{
      margin-left: -3px;
      margin-right: 0;
      flex-flow: row;
      ${({ show }) => show && css`
        opacity: 1;
      `};
    }
    &.right{
      margin-left: -3px ;
      margin-right: 0;
      flex-flow: row;
      ${({ show }) => show && css`
        opacity: 1;
      `};
    }
  }
`;
const DivColumn = styled.div`
  display: flex;
  flex-flow: column;
`;
const DivLastPoint = styled.div`
  display: flex;
  justify-content: center;
  @media screen and (max-width: 600px){
    justify-content: flex-start;
    margin-left: -3px;
    div{
      margin: 0;
    }
  }
`;
const Point = styled.div`
  min-width: 8px;
  min-height: 8px;
  width: 8px;
  height: 8px;
  background: #5BB990;
  border-radius: 8px;
  margin-top: 12px;
  @media screen and (max-width: 1100px){
    margin-right: 16px;
  }
  @media screen and (max-width: 600px){
    margin-top: 8px;
    margin-right: 8px;
  }
`;
const TitleText = styled(H5)`
  display: flex;
  margin-bottom: 16px;
`;
const Text = styled(Text16)`
  p{
    margin-block-start: 1em;
    margin-block-end: 1em;
    margin-inline-start: 0px;
    margin-inline-end: 0px;
  }
  p:first-child{
    margin-block-start: 0;
  }
`;