import { gql, useLazyQuery } from "@apollo/client";
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { ReactSVG } from 'react-svg';
import styled, { css } from 'styled-components';
import { Button, Container, H5, Section, Text12, Text14, Wrapper } from '../../atoms';
import { Intro } from '../../molecules';

export default function NewsPage({ noResults, smallTitle, title, placeholderSearch, placeholderStart, placeholderEnd }) {

  const [news, setNews] = useState({ rows: null, pagination: { currentPage: 1, lastPage: 1 } });
  const [search, setSearch] = useState("");
  const [startDate, setStartDate] = useState(undefined);
  const [endDate, setEndDate] = useState(undefined);

  const [getNews, { loading }] = useLazyQuery(
    gql`
        query frontendArticles($perPage: Int, $currentPage: Int, $search: String, $beginDate: String, $endDate: String){
          frontendArticles(perPage: $perPage, currentPage: $currentPage, search: $search, beginDate: $beginDate, endDate: $endDate){
            rows {
                id
                slug
                title
                excerpt
                date
            }
            pagination {
              currentPage
              lastPage
            }
          }
        }
    `,
    {
      fetchPolicy: 'network-only',
      onError: (error) => { console.log(error) },
      onCompleted: (data) => {
        if (data && data.frontendArticles) {
          if (data.frontendArticles.pagination.currentPage > 1) {
            setNews({ rows: [...news.rows, ...data.frontendArticles.rows], pagination: data.frontendArticles.pagination });
          } else {
            setNews(data.frontendArticles);
          }
        }
      }
    }
  );



  const requestData = (params = {}) => {
    const { perPage = 8, currentPage = 1 } = params;

    getNews({
      variables: {
        perPage: perPage,
        currentPage: currentPage,
        search: search && search.length > 3 ? search : undefined,
        beginDate: startDate ? moment(startDate).format('YYYY-MM-DD') : undefined,
        endDate: endDate ? moment(endDate).format('YYYY-MM-DD') : undefined
      }
    });
  }

  useEffect(() => {
    requestData();
  }, [search, startDate, endDate]);

  return (
    <>
      <Section backgroundColor="#5BB990" noFade>
        <Container lg>
          <Wrapper bothInitial noHeight>
            <Intro
              smallTitleColor="#368D67"
              smallTitle={smallTitle}
              titleSize="H2"
              title={title}
              titleColor="#35383B"
              titleMaxWidth="960px"
              titleFontFamily="AeonikRegular"
            />
          </Wrapper>
        </Container>
      </Section>
      <Section backgroundColor="#fff" noFade style={{ flex: 1 }}>
        <Wrapper bottom noHeight >
          <Container lg>
            <DivContainer>
              <DivInput>
                <Input type="text" placeholder={placeholderSearch} onChange={(e) => setSearch(e.target.value)} />
                <ButtonSubmit>
                  <SearchIcon src="/static/images/icon_search.svg" />
                </ButtonSubmit>
              </DivInput>
              <DivInput date>
                <Input width={176} type="date" placeholder={placeholderStart} value={startDate} onChange={(e) => setStartDate(e.target.value)} />
              </DivInput>
              <DivInput date>
                <Input width={176} type="date" placeholder={placeholderEnd} value={endDate} onChange={(e) => setEndDate(e.target.value)} />
              </DivInput>
            </DivContainer>
            <ContainerNews>
              {!!news.rows && <>
                {news.rows.map((item, index) => (
                  <Item key={String(index)}>
                    <Text12 color="#B2B6B9" dangerouslySetInnerHTML={{ __html: moment(item.date).format('DD.MM.YYYY') }} />
                    <Margin />
                    <H5 color="#35383B" style={{ fontSize: 24 }} height={29} maxWidth="242px" dangerouslySetInnerHTML={{ __html: (item.title.length > 48) ? item.title.slice(0, 48) + "..." : item.title }} />
                    <Margin margin={2} />
                    <Text14 color="#B2B6B9" style={{ minHeight: 72 }} height={24} maxWidth="242px" dangerouslySetInnerHTML={{ __html: (item.excerpt.length > 84) ? item.excerpt.slice(0, 84) + "..." : item.excerpt }} />
                    <Margin margin={4} noticia />
                    <ButtonNews to={'noticias' + item.slug}>
                      <Text14 color="#368D67" maxWidth="258px" dangerouslySetInnerHTML={{ __html: 'Ler mais' }} />
                      <Icon src="/static/images/arrow_right.svg" />
                    </ButtonNews>
                  </Item>
                ))}
                {(news.rows.length === 0) &&
                  <p>{noResults}</p>
                }
              </>}
            </ContainerNews>
            {news.pagination.currentPage < news.pagination.lastPage &&
              <DivButtonLoadMore>
                <Button text="Ver mais" onClick={() => requestData({ currentPage: news.pagination.currentPage + 1 })} loading={loading} />
              </DivButtonLoadMore>
            }
          </Container>
        </Wrapper>
      </Section>
    </>
  )
}

const Margin = styled.div`
  margin-top: ${({ margin }) => margin ? margin * 8 : 8}px;
  @media screen and (max-width: 670px){
    ${({ noticia }) => noticia && css`
      margin-top: 6px;
    `}
  }
`;
const DivContainer = styled.div`
  display: flex;
  flex-flow: row;
  flex-wrap: wrap;
  height: 100%;
  margin: 24px -20px 40px -20px;
  @media screen and (max-width: 670px){
    margin: 24px 0 20px 0;
  }
`;

const ContainerNews = styled.div`
  display: flex;
  flex-flow: row;
  flex-wrap: wrap;
  /* justify-content: center; */
  margin-bottom: -60px;
`;
const Item = styled.div`
  width: 25%;
  height: 260px;
  display: flex;
  flex-flow: column;
  margin-bottom: 60px;
  box-sizing: border-box;
  @media screen and (max-width: 1200px){
    width: 50%;
  }
  @media screen and (max-width: 670px){
    width: 100%;
    height: 240px;
    margin-bottom: 24px;
    h5{
      max-width: 90%;
    }
    div{
      max-width: 90%;
    }
  }
`;
const Icon = styled.img`
  width: 14px;
  height: 12px;
  object-fit: contain;
  margin-left: 8px;
  transition: all 0.4s ease-in-out;
`;
const ButtonNews = styled(Link)`
  display: flex;
  flex-flow: row;
  align-items: center;
  :hover{
    cursor: pointer;
    ${Icon}{
      margin-left: 12px;
    }
  }
`;
const DivButtonLoadMore = styled.div`
  margin-top: 80px;
  display: flex;
  flex-flow: row;
  justify-content: center;
  width: 100%;
`;
const DivInput = styled.div`
  position: relative;
  display: flex;
  max-width: 100%;
  margin: 10px;
  :last-child{
    margin-right:0;
  }
  @media screen and (max-width: 670px){
    margin: 0;
    margin-bottom: 16px;
    ${({ date }) => date && css`
      margin-right: 20px
      width: calc(50% - 10px);
    `}
  }
`;
const Input = styled.input`
  width: ${({ width }) => width ? width : 568}px;
  max-width: 100%;
  height: 52px;
  background: #EBECED;
  border-radius: 2px;
  padding: ${({ width }) => width ? '12px 16px 12px 16px' : '12px 40px 12px 16px'};
  transition: all 0.4s ease-in-out;
  color: #35383B;
  box-sizing: border-box;
  border: unset;
  font-family: 'AeonikMedium',sans-serif;
  ::placeholder{
    color: #B2B6B9;
  }
  :focus{
    border-color: #B2B6B9;
  }
  :hover{
    cursor: pointer;
  }
`;
const SearchIcon = styled(ReactSVG)`
  svg{
    path{
      transition: all 0.5s ease-in-out;
      stroke: #B2B6B9;
    }
    circle{
      transition: all 0.5s ease-in-out;
      stroke: #B2B6B9;
    }
  }
`;
const ButtonSubmit = styled.div`
  position: absolute;
  top: 16px;
  right: 16px;
  padding: 0;
  margin: 0;
`;