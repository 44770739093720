export const NORMALIZE_STRING = (string) => {
    return string.normalize("NFD").replace(/[\u0300-\u036f]/g, "").toLowerCase();
}

export const SORTED = (array) => {
    return array.sort(function (a, b) {
        if (NORMALIZE_STRING(a.name) < NORMALIZE_STRING(b.name)) { return -1; }
        if (NORMALIZE_STRING(a.name) > NORMALIZE_STRING(b.name)) { return 1; }
        return 0;
    })
}