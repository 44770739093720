import React, { useEffect, useState } from 'react';
import styled, { css } from 'styled-components';
import { H5, Modal, Text12 } from '../../atoms';

export default function ModalProduct(props) {

  const {
    isOpen,
    children,
    content,
    toogleModal
  } = props;

  const [animation, setAnimation] = useState(false);

  const afterOpenModal = () => {
    setAnimation(true);
  };

  const afterCloseModal = () => {
    setAnimation(false);
  };

  const onRequestClose = () => {
    setAnimation(false);
  };

  useEffect(() => {
    if (isOpen === false) {
      onRequestClose();
    }
  }, [isOpen])

  return (
    <Modal isOpen={isOpen} afterOpenModal={afterOpenModal} afterCloseModal={afterCloseModal}>
      <Overlay animation={animation} />
      <Content animation={animation}>
        <Structure>
          <ButtonModal onClick={() => toogleModal()}>
            <Icon src="/static/images/icon_close_black.svg" />
          </ButtonModal>
          <ContentImage>
            <img src={content?.item?.url} />
          </ContentImage>
          <ContentText>
            {content?.title &&
              <H5 color="#5D6368" maxWidth="274px" dangerouslySetInnerHTML={{ __html: content.title }} />
            }
            {content?.description &&
              <Text12 style={{ marginTop: content?.title ? 24 : 0 }} color="#5D6368" maxWidth="470px" dangerouslySetInnerHTML={{ __html: content.description }} />
            }
          </ContentText>
        </Structure>
      </Content>
    </Modal>
  )
}

const Overlay = styled.div`
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background-color: rgba(0,0,0,0);
  transition: all 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94);
  ${({ animation }) => animation && css`
    background-color: rgba(0,0,0,0);
  `}
`;
const Structure = styled.div`
  width: 100%;
  min-height: 100%;
  display: flex;
  flex-flow: column;
  box-sizing: border-box;
  background: #EBECED;
  padding: 26px;
  transition: all 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94);
  transform: translateX(-100%);
`;
const Content = styled.div`
  position: relative;
  z-index: 2;
  width: 100%;
  min-height: 100%;
  display: flex;
  opacity: 0;
  transition: all 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94);
  ${({ animation }) => animation && css`
    opacity: 1;
    ${Structure} {
      transform: translateX(0%);
    }
  `};
  @media screen and (max-width: 1200px){
    width:100%;
  }
`;
const ButtonModal = styled.div`
  position: absolute;
  left: 24px;
  top: 24px;
  display: flex;
  flex-flow: row;
  align-items: center;
  justify-content: center;
  transition: 0.4s ease-in-out;
  z-index: 1;
  :hover{
    cursor: pointer;
  }
`;
const Icon = styled.img`
  height: 20px;
  width: 20px;
  max-height: 20px;
  max-width: 20px;
  object-fit: contain;
`;
const ContentImage = styled.div`
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 24px;
  img{
    max-height: 100%;
    width: 852px;
    max-width: 100%;
    object-fit: contain;
  }
`;
const ContentText = styled.div`
  display: flex;
  flex-flow: column;
`;