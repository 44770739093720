import React from 'react';
import styled, { css } from 'styled-components';
import { Container, H4, Section, Text16, Text18, Wrapper } from '../../atoms';
import { Link, Element, Events, animateScroll as scroll, scrollSpy, scroller } from 'react-scroll'

export default function TextAboutSection({ arr }) {

  if (arr && arr.length > 0) {
    return (
      <Section backgroundColor="#fff">
        <Container md>
          <Wrapper both noHeight>
            <DivContainer>
              <DivSection>
                <DivColumnTitle>
                  {(arr && arr.length > 0) && arr.map((innerText, index) => (
                    <TitleSection
                      key={String(index)}
                      font="AeonikRegular"
                      activeClass='active'
                      to={innerText.key}
                      spy={true}
                      smooth={true}
                      duration={500}
                      delay={50}
                      offset={-140}
                    >
                      <Text16 dangerouslySetInnerHTML={{ __html: innerText.title }} />
                    </TitleSection>
                  ))}
                </DivColumnTitle>
              </DivSection>
              <DivColumn>
                {(arr && arr.length > 0) && arr.map((innerText, i) => (
                  <div key={String(i)}>
                    <TitleText color="#35383B" font="AeonikBold" dangerouslySetInnerHTML={{ __html: innerText.title }} />
                    <Text
                      className={innerText.key}
                      font="AeonikRegular"
                      color="#5D6368"
                      maxWidth="960px"
                      dangerouslySetInnerHTML={{ __html: innerText.text }}
                    />
                  </div>
                ))}
              </DivColumn>
            </DivContainer>
          </Wrapper>
        </Container>
      </Section>
    )
  }
  return null
}

const DivSection = styled.div`
  display: block;
  @media screen and (max-width: 800px){
    display: none;
  }
`;
const DivContainer = styled.div`
  display: flex;
  flex-flow: row;
`;
const DivColumnTitle = styled.div`
  position: sticky;
  top: 120px;
  display: flex;
  flex-flow: column;
  width: 176px;
`;
const DivColumn = styled.div`
  display: flex;
  flex-flow: column;
`;
const TitleSection = styled(Link)`
  margin-bottom: 8px;
  :last-child{
    margin-bottom: 0;
  }
  div {
    color: #B2B6B9;
    transition: all 0.4s ease-in-out;
  }
  :hover{
    cursor: pointer;
    div{
      color: #35383B;
    }
  }
  &.active{
    div {
      font-family: AeonikBold ,sans-serif;
      color: #35383B;
    }
  }
`;
const TitleText = styled(Text18)`
  @media screen and (min-width: 801px){
    display: none;
  }
  @media screen and (max-width: 800px){
    display: flex;
    margin-bottom: 16px;
  }
`;
const Text = styled(H4)`
   margin-bottom: calc(60px + (120 - 60) * ((100vw - 375px) / (1920 - 375)));
  :last-child{
    margin-bottom: 0;
  }
  p{
    margin-block-start: 1em;
    margin-block-end: 1em;
    margin-inline-start: 0px;
    margin-inline-end: 0px;
  }
  p:first-child{
    margin-block-start: 0;
  }
  @media screen and (max-width: 768px){
    line-height: 32px;
  }
`;