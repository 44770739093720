import React from 'react';
import { useParams } from 'react-router';
import styled from 'styled-components';
import { Button, Container, H4, Section, Wrapper } from '../../atoms';
import { Intro } from '../../molecules';

export default function AboutSection({ buttonText, buttonLink, certifications, smallTitleCertifications, text, titleCertifications }) {

  let { language } = useParams();

  return (
    <Section backgroundColor="#fff" className='about'>
      <Wrapper both noHeight >
        <Container sm>
          <DivContainer>
            <Title color="#5D6368" font="AeonikRegular" dangerouslySetInnerHTML={{ __html: text }} />
            <Margin margin={4} />
            <Button text={buttonText} link={'/' + language + buttonLink} />
            <Margin margin={10} />
            <Intro
              smallTitleColor="#368D67"
              smallTitle={smallTitleCertifications}
              titleSize="H4"
              title={titleCertifications}
              titleColor="#35383B"
            />
            <Margin margin={2} />
            <DivCertifications>
              {(certifications && certifications.length > 0) && certifications.map((certification, index) => (
                <a key={String(index)} href={certification?.link} target='__blank'>
                  <img src={certification?.img?.url} />
                </a>
              ))}
            </DivCertifications>
          </DivContainer>
        </Container>
      </Wrapper>
    </Section>
  )
}

const Margin = styled.div`
  margin-top: ${({ margin }) => margin ? margin * 8 : 8}px;
`;
const DivContainer = styled.div`
  display: flex;
  flex-flow: column;
  height: 100%;
`;
const Title = styled(H4)`
  p{
    margin-block-start: 1em;
    margin-block-end: 1em;
    margin-inline-start: 0px;
    margin-inline-end: 0px;
  }
  p:last-child{
    margin-block-start: 0;
    margin-block-end: 0;
    margin-inline-start: 0px;
    margin-inline-end: 0px;
  }
`;
const DivCertifications = styled.div`
  display: flex;
  flex-flow: row;
  a{
    margin-right: 60px;
  }
  a:last-child{
    margin-right: 0;
  }
  img{
    max-width: 100%;
    max-height: 160px;
    object-fit: contain;
  }
`;