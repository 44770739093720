import React from 'react';
import styled from 'styled-components';
import useLang from '../../utils/useLang';
import { Navbar } from '../molecules';
import ProductsDetailSection from '../organisms/productsDetailSection';

function Produto() {

  return (
    <DivColumn>
      <Navbar />
      <ProductsDetailSection/>
    </DivColumn>
  )
} export default useLang(Produto);

const DivColumn = styled.div`
  display: flex;
  flex-flow: column;
`;