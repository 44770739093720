import React from 'react';
import styled from 'styled-components';
import useLang from '../../utils/useLang';
import SubProductsDetailSection from '../organisms/subProductsDetailSection';

function SubProduto() {

  return (
    <DivColumn>
      <SubProductsDetailSection/>
    </DivColumn>
  )
} export default useLang(SubProduto);

const DivColumn = styled.div`
  display: flex;
  flex-flow: column;
`;