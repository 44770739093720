import { gql, useLazyQuery } from '@apollo/client';
import React, { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import styled from 'styled-components';
import useLang from '../../utils/useLang';
import { Footer, Loading, Navbar } from '../molecules';
import Organisms from '../organisms';

function MainComponent() {

  let { language, slug } = useParams();

  const [content, setContent] = useState(null);
  const [metatags, setMetatags] = useState(null);
  const [loading, setLoading] = useState(false);

  const history = useHistory();

  const [settingsGet] = useLazyQuery(
    gql`
      query frontendPage($slug: String!){
        frontendPage (slug: $slug){
          content
          metatags
        }
      }
    `,
    {
      fetchPolicy: 'network-only',
      onError: (error) => {
        if (error?.graphQLErrors[0]?.message === "error.resource_not_found") {
          history.push(`/${language}/404`);
        } else {
          history.push(`/${language}/500`);
        }
      },
      onCompleted: (data) => {
        if (data?.frontendPage && data?.frontendPage?.metatags) {
          setMetatags(data.frontendPage.metatags);
        }
        if (data?.frontendPage && data?.frontendPage?.content) {
          setContent(data.frontendPage.content);
        }
      }
    }
  )

  useEffect(() => {
    settingsGet({ variables: { slug: slug ? slug : '/' } });
    setLoading(true);
    setTimeout(() => {
      setLoading(false);
    }, 2000);
  }, [slug, language]);

  useEffect(() => {
    if( metatags !== null){
      insertMetatags(metatags)
    }
  }, [metatags]);

  
  const insertMetatags = (meta) => {
    if (meta !== null) {
      
      const {
        title,
        description,
        keywords
      } = meta;
      
      if(title) {
        document.getElementById("ogtitle").setAttribute("content", title);
      }
      if(description) {
        document.getElementById("ogdescription").setAttribute("content", description);
      }
      if(keywords) {
        document.getElementById("keywords").setAttribute("content", keywords.toString());
      }
    }
  }

  if (loading) {
    return (<Loading lottie={true} isLoading={loading} />)
  } else {
    if (content) {
      return (
        <DivColumn>
          <Navbar />
          {Object.keys(content).map((keyName, i) => {
            const Component = Organisms[keyName];
            return <Component key={String(i)} {...content[keyName]} language={language} />
          })}
          <Footer slug={slug}/>
        </DivColumn>
      )
    }
    return null;
  }

}
export default useLang(MainComponent);

const DivColumn = styled.div`
  display: flex;
  flex-flow: column;
  flex:1;
`;