import { gql, useLazyQuery } from "@apollo/client";
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { Button, Container, H5, Section, Text12, Text14, Wrapper } from '../../atoms';
import { Intro } from '../../molecules';

export default function NewsSection({ buttonLink, buttonText, textLoad, language, smallTitle, buttonTextSee, noResults, title }) {

  const [limit, setLimit] = useState(4);
  const [news, setArrNews] = useState([]);

  const [getNews, { loading }] = useLazyQuery(
    gql`
        query frontendArticles {
          frontendArticles(currentPage: 1, perPage: 4) {
            rows {
              id
              slug
              title
              excerpt
              date
            }
          }
        }
    `,
    {
      fetchPolicy: 'network-only',
      onError: (error) => { console.log(error) },
      onCompleted: (data) => {
        if (data && data.frontendArticles && data.frontendArticles.rows.length > 0) {
          const newArray = data.frontendArticles.rows.map(x => ({
            cursor: x.id,
            content: x,
          }));
          setArrNews(newArray);
        }
      }
    }
  );

  useEffect(() => {
    getNews();
  }, [limit]);


  return (
    <Section backgroundColor="#F7F8F8" >
      <Wrapper both noHeight >
        <Container md>
          <DivRowSpace>
            <DivContainer>
              <Intro
                smallTitleColor="#368D67"
                smallTitle={smallTitle}
                titleSize="H3"
                title={title}
                titleColor="#35383B"
              />
            </DivContainer>
            <Button text={buttonText} link={'/' + language + buttonLink} color="368D67" />
          </DivRowSpace>
          <ContainerNews>
            {(news && news.length > 0) && news.map((item, index) => (
              <Item key={String(index)}>
                <Text12 color="#B2B6B9" dangerouslySetInnerHTML={{ __html: moment(item.content.date).format('DD.MM.YYYY') }} />
                <Margin />
                <H5 color="#35383B" height={29} maxWidth="242px" dangerouslySetInnerHTML={{ __html: (item.content.title.length > 48) ? item.content.title.slice(0, 48) + "..." : item.content.title }} />
                <Margin margin={2} />
                <Text14 color="#B2B6B9" height={24} maxWidth="242px" dangerouslySetInnerHTML={{ __html: (item.content.excerpt.length > 84) ? item.content.excerpt.slice(0, 84) + "..." : item.content.excerpt }} />
                <Margin margin={4} />
                <ButtonNews to={'noticias' + item.content.slug}>
                  <Text14 color="#368D67" maxWidth="258px" dangerouslySetInnerHTML={{ __html: buttonTextSee }} />
                  <Icon src="/static/images/arrow_right.svg" />
                </ButtonNews>
              </Item>
            ))}
            {(news && news.length === 0) &&
              <H5 color='#35383B' dangerouslySetInnerHTML={{ __html: noResults }} />
            }
          </ContainerNews>
        </Container>
      </Wrapper>
    </Section>
  )
}

const Margin = styled.div`
  margin-top: ${({ margin }) => margin ? margin * 8 : 8}px;
`;
const DivContainer = styled.div`
  display: flex;
  flex-flow: column;
  height: 100%;
  margin-bottom: 72px;
  @media screen and (max-width: 760px){
    margin-bottom: 24px;
  }
`;
const DivRowSpace = styled.div`
  display: flex;
  flex-flow: row;
  justify-content: space-between;
  @media screen and (max-width: 760px){
    flex-flow: column;
    margin-bottom: 40px;
  }
`;
const ContainerNews = styled.div`
  display: flex;
  flex-flow: row;
  flex-wrap: wrap;
  /* justify-content: center; */
  margin-bottom: -16px;
`;
const Item = styled.div`
  width: 25%;
  height: 260px;
  display: flex;
  flex-flow: column;
  margin-bottom: 32px;
  box-sizing: border-box;
  @media screen and (max-width: 1200px){
    width: 50%;
  }
  @media screen and (max-width: 670px){
    width: 100%;
  }
`;
const Icon = styled.img`
  width: 14px;
  height: 12px;
  object-fit: contain;
  margin-left: 8px;
  transition: all 0.4s ease-in-out;
`;
const ButtonNews = styled(Link)`
  display: flex;
  flex-flow: row;
  align-items: center;
  :hover{
    cursor: pointer;
    ${Icon}{
      margin-left: 12px;
    }
  }
`;
const DivButtonLoadMore = styled.div`
  margin-top: 80px;
  display: flex;
  flex-flow: row;
  justify-content: center;
  width: 100%;
`;