import { createGlobalStyle } from 'styled-components';
import AeonikBlack from './fonts/Aeonik-Black.ttf';
import AeonikBold from './fonts/Aeonik-Bold.ttf';
import AeonikMedium from './fonts/Aeonik-Medium.ttf';
import AeonikRegular from './fonts/Aeonik-Regular.ttf';
import Rockness from './fonts/Rockness.otf';

export default createGlobalStyle`

/* FONTS */

@font-face {
  font-family: 'AeonikBlack';
  src: url(${AeonikBlack}) format('truetype');
}
@font-face {
  font-family: 'AeonikBold';
  src: url(${AeonikBold}) format('truetype');
}
@font-face {
  font-family: 'AeonikMedium';
  src: url(${AeonikMedium}) format('truetype');
}
@font-face {
  font-family: 'AeonikRegular';
  src: url(${AeonikRegular}) format('truetype');
}
@font-face {
  font-family: 'Rockness';
  src: url(${Rockness}) format('truetype');
}

html, body, div, span, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
abbr, address, cite, code,
del, dfn, em, img, ins, kbd, q, samp,
small, strong, sub, sup, var,
b, i,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, figcaption, figure, 
footer, header, hgroup, menu, nav, section, summary,
time, mark, audio, video {
  margin:0;
  padding:0;
  border:0;
  outline:0;
  font-size:100%;
  vertical-align:baseline;
  background:transparent;
  scroll-behavior: smooth;
}
body {
  line-height:1;
  font-family: 'AeonikMedium', sans-serif;
}
article,aside,details,figcaption,figure,
footer,header,hgroup,menu,nav,section { 
  display:block;
}
nav ul li {
  list-style:none;
  list-style-type: none;
}
blockquote, q {
  quotes:none;
}
blockquote:before, blockquote:after,
q:before, q:after {
  content:'';
  content:none;
}
a {
  margin:0;
  padding:0;
  font-size:100%;
  vertical-align:baseline;
  background:transparent;
  text-decoration: none;
  display: inline-block;
}
ins {
  background-color:#ff9;
  color:#000;
  text-decoration:none;
}
mark {
  background-color:#ff9;
  color:#000; 
  font-style:italic;
  font-weight:bold;
}
del {
  text-decoration: line-through;
}
abbr[title], dfn[title] {
  border-bottom:1px dotted;
  cursor:help;
}
table {
  border-collapse:collapse;
  border-spacing:0;
}
hr {
  display:block;
  height:1px;
  border:0;   
  border-top:1px solid #cccccc;
  margin:1em 0;
  padding:0;
}
input, select, textarea {
  vertical-align:middle;
}
input:focus, textarea:focus{
  outline: none;
}
button {
  border: none;
  background-color: transparent;
}
button:focus {
  outline: none;
}
button:hover {
  cursor: pointer;
}
ul {
  list-style-position: inside;
}
`;