import React, { useContext, useState } from 'react';
import { useCookies } from 'react-cookie';
import { Link as RouterLink } from 'react-router-dom';
import { ReactSVG } from 'react-svg';
import styled, { css } from 'styled-components';
import { store } from '../../../utils/store';
import { Container, Section, Text12, Text16, Text18 } from '../../atoms';

export default function Footer({ slug }) {

  const { menu } = useContext(store);
  const [cookies] = useCookies(['language']);

  let lang = cookies.language;

  return (
    <Section backgroundColor="#35383B">
      <Container lg>
        <ContainerFooter>
          <Logo className='tablet' src={menu?.logoFooter?.url} />
          <TopDiv>
            <Logo src={menu?.logoFooter?.url} />
            <Item>
              <Link to={`/${lang}${menu?.linkMedidata}`}>
                <Text12 color="#5D6368" font="AeonikBlack" style={{ textTransform: 'uppercase', letterSpacing: '0.1em' }} height={24} dangerouslySetInnerHTML={{ __html: menu?.titleMedidata }} />
              </Link>
              <Margin />
              {(menu?.listMedidata && menu?.listMedidata.length > 0) && menu.listMedidata.map((item, index) => (
                <Link to={`/${lang}${item.link}`} key={String(index)}>
                  <Text16 color="#B2B6B9" height={24} style={{ textTransform: 'capitalize' }} dangerouslySetInnerHTML={{ __html: item.title }} />
                </Link>
              ))}
            </Item>
            <Item>
              <Link to={`/${lang}${menu?.linkProdutos}`}>
                <Text12 color="#5D6368" font="AeonikBlack" style={{ textTransform: 'uppercase', letterSpacing: '0.1em' }} height={24} dangerouslySetInnerHTML={{ __html: menu?.titleProducts }} />
              </Link>
              <Margin />
              {(menu?.listProducts && menu?.listProducts.length > 0) && menu.listProducts.map((item, index) => (
                <Link to={`/${lang}/produtos${item.link}`} key={String(index)}>
                  <Text16 color="#B2B6B9" height={24} style={{ textTransform: 'capitalize' }} dangerouslySetInnerHTML={{ __html: item.title }} />
                </Link>
              ))}
            </Item>
            <Item>
              <Link to={`/${lang}${menu?.linkServices}`}>
                <Text12 color="#5D6368" font="AeonikBlack" style={{ textTransform: 'uppercase', letterSpacing: '0.1em' }} height={24} dangerouslySetInnerHTML={{ __html: menu?.titleServices }} />
              </Link>
              <Margin />
              <Link to={`/${lang}${menu?.linkNews}`}>
                <Text12 color="#5D6368" font="AeonikBlack" style={{ textTransform: 'uppercase', letterSpacing: '0.1em' }} height={24} dangerouslySetInnerHTML={{ __html: menu?.titleNews }} />
              </Link>
              <Margin />
              <Link to={`/${lang}${menu?.linkDownloads}`}>
                <Text12 color="#5D6368" font="AeonikBlack" style={{ textTransform: 'uppercase', letterSpacing: '0.1em' }} height={24} dangerouslySetInnerHTML={{ __html: menu?.titleDownloads }} />
              </Link>
              <Margin />
              <Link to={`/${lang}${menu?.linkContacts}`}>
                <Text12 color="#5D6368" font="AeonikBlack" style={{ textTransform: 'uppercase', letterSpacing: '0.1em' }} height={24} dangerouslySetInnerHTML={{ __html: menu?.titleContacts }} />
              </Link>
            </Item>
            <Item>
              <Text12 color="#5D6368" font="AeonikBlack" style={{ textTransform: 'uppercase', letterSpacing: '0.1em' }} height={24} dangerouslySetInnerHTML={{ __html: menu?.labelNewsletter }} />
              <Margin />
              <Text16 color="#B2B6B9" height={19} maxWidth="196px" dangerouslySetInnerHTML={{ __html: menu?.textNewsletter }} />
              <Margin margin={1.5} />
              <Form href="http://rgpd.medidata.pt/opt-in" target="__blank">
                <Input type="email" placeholder={menu?.placeholderNewsletter} />
                <ButtonSubmit>
                  <ArrowDiv src="/static/images/arrow_right.svg" />
                </ButtonSubmit>
              </Form>
            </Item>
            <Item>
              <Text12 color="#5D6368" font="AeonikBlack" style={{ textTransform: 'uppercase', letterSpacing: '0.1em' }} height={24} dangerouslySetInnerHTML={{ __html: menu?.titleSocial }} />
              <Margin />
              <DivRow>
                <ButtonSocial href={menu?.linkFace} target="__blank">
                  <IconSocial src="/static/images/icon_facebook.svg" />
                </ButtonSocial>
                <ButtonSocial href={menu?.linkLinkedin} target="__blank">
                  <IconSocial src="/static/images/icon_linkedin.svg" />
                </ButtonSocial>
              </DivRow>
            </Item>
          </TopDiv>
          <Margin margin={10} marginBig/>
          <BottomDiv>
            <Text18 color="#5BB990" height={22} dangerouslySetInnerHTML={{ __html: 'Sistemas de informação para Autarquias' }} />
            <DivRow footer={true}>
              <Text12 color="#5D6368" height={24} dangerouslySetInnerHTML={{ __html: menu?.textRights }} />
              <DivRow footerLegal={true}>
                <Link to={`/${lang}/legal#politica`}>
                  <Text12 color="#5D6368" height={24} dangerouslySetInnerHTML={{ __html: menu?.textPolicy }} />
                </Link>
                <TextSeparatorLegal color="#5D6368" height={24} dangerouslySetInnerHTML={{ __html: '.' }} />
                <Link to={`/${lang}/legal#cookies`}>
                  <Text12 color="#5D6368" height={24} dangerouslySetInnerHTML={{ __html: menu?.textCookies }} />
                </Link>
              </DivRow>
            </DivRow>
          </BottomDiv>
        </ContainerFooter>
      </Container>
    </Section>
  )
}

const Margin = styled.div`
  margin-top: ${({ margin }) => margin ? margin * 8 : 8}px;
  @media screen and (max-width: 760px){
    ${({ marginBig }) => marginBig && css`
      margin-top: 40px;
    `}
  }
`;
const ContainerFooter = styled.div`
  display: flex;
  flex-flow: column;
  padding-top: 68px;
  padding-bottom: 56px;
`;
const TopDiv = styled.div`
  display: flex;
  flex-flow: row;
  justify-content: space-between;
  @media screen and (max-width: 760px){
    flex-flow: column;
  }
`;
const Logo = styled.img`
  height: 32px;
  max-width: 112px;
  object-fit: contain;
  &.tablet{
    display: none;
  }
  @media screen and (max-width: 1000px){
    display: none;
    &.tablet{
      display: block;
      margin-bottom: 32px;
    }
  }
  @media screen and (max-width: 760px){
    display: block;
    margin-bottom: 32px;
    &.tablet{
      display: none;
    }
  }
`;
const Item = styled.div`
  display: flex;
  flex-flow: column;
  @media screen and (max-width: 1000px){
    margin-bottom: 22px;
  }
`;
const Link = styled(RouterLink)`
  margin-bottom: 4px;
  :last-of-type{
    margin-bottom: 0;
  }
`;

const DivRow = styled.div`
  display: flex;
  flex-flow: row;
  ${({ footer }) => footer && css`
    @media screen and (max-width: 700px){
      flex-flow: column;
      margin-top: 16px;
    }
  `}
  ${({ footerLegal }) => footerLegal && css`
    margin-left: 16px;
    @media screen and (max-width: 700px){
      margin-left: 0;
    }
  `}
`;
const IconSocial = styled.img`
  max-height: 15px;
  max-width: 15px;
  object-fit: contain;
`;
const ButtonSocial = styled.a`
  width: 40px;
  height: 40px;
  border-radius: 40px;
  display: flex;
  flex-flow: row;
  align-items: center;
  justify-content: center;
  background-color: #5D6368;
  margin-right: 8px;
  transition: all 0.4s ease-in-out;
  :last-of-type{
    margin-right: 0;
  }
  :hover{
    cursor: pointer;
    background-color: #B2B6B9;
  }
`;
const BottomDiv = styled.div`
  display: flex;
  flex-flow: column;
`;
const TextSeparatorLegal = styled(Text12)`
  margin-left: 4px;
  margin-right: 4px;
`;
const Form = styled.a`
  position: relative;
  display: flex;
  flex-flow: column;  
  width: 196px;
`;
const Input = styled.input`
  width: 196px;
  height: 44px;
  border: 1px solid #5D6368;
  background: transparent;
  box-sizing: border-box;
  border-radius: 2px;
  padding: 12px 40px 12px 16px;
  transition: all 0.4s ease-in-out;
  color: #B2B6B9;
  pointer-events: none;
  ::placeholder{
    color: #5D6368;
  }
  :hover{
    border-color: #B2B6B9;
  }
`;
const ButtonSubmit = styled.button`
  position: absolute;
  top: 16px;
  right: 16px;
  padding: 0;
  margin: 0;
`;
const ArrowDiv = styled(ReactSVG)`
  svg{
    path{
      transition: all 0.5s ease-in-out;
      stroke: #5D6368;
    }
  }
  :hover{
    cursor: pointer;
    svg{
      path{
        transition: all 0.5s ease-in-out;
        stroke: #B2B6B9;
      }
    }
  }
`;