import AboutSection from './aboutSection';
import Banner from './banner';
import BannerAbout from './bannerAbout';
import BannerClientes from './bannerClientes';
import BannerContacts from './bannerContactos';
import BannerDownloads from './bannerDownloads';
import BannerServico from './bannerServico';
import CaseStudies from './caseStudies';
import ClientsSection from './clientsSection';
import ContactsSection from './contactsSection';
import ContentContacts from './contentContacts';
import ContentContactsMap from './contentContactsMap';
import LegalInformation from './legalInformation';
import MedidataMundo from './medidataMundo';
import MedidataMundoAbout from './medidataMundoAbout';
import NewsPage from './news';
import NewsDetail from './newsDetail';
import NewsSection from './newsSection';
import NossoCaminho from './nossoCaminho';
import ProductsDetailSection from './productsDetailSection';
import ProductsSection from './productsSection';
import ProductsSectionHome from './productsSectionHome';
import SubProductsDetailSection from './subProductsDetailSection';
import TextAboutSection from './textAboutSection';
import TextServicoSection from './textServicoSection';

export default{
  Banner,
  BannerAbout,
  BannerServico,
  BannerClientes,
  BannerDownloads,
  BannerContacts,
  AboutSection,
  LegalInformation,
  MedidataMundo,
  MedidataMundoAbout,
  NossoCaminho,
  CaseStudies,
  ContentContacts,
  ContentContactsMap,
  ProductsSectionHome,
  ProductsSection,
  NewsPage,
  NewsDetail,
  NewsSection,
  ContactsSection,
  TextAboutSection,
  TextServicoSection,
  ClientsSection,
  ProductsDetailSection,
  SubProductsDetailSection
}