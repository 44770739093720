import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import GlobalStyle from './styles/global';
import { StateProvider } from './utils/store';
import moment from 'moment-timezone';
moment().tz("Europe/Lisbon").format();

function Init({ children }) {
  return (
    <>
      <GlobalStyle />
      {children}
    </>
  );
}

ReactDOM.render(<Init children={<StateProvider><App /></StateProvider>} />, document.getElementById('root'));