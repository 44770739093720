import { gql, useLazyQuery } from "@apollo/client";
import React, { useContext, useEffect, useState } from 'react';
import { Collapse } from 'react-collapse';
import { Link, useHistory, useParams } from 'react-router-dom';
import styled, { css } from 'styled-components';
import { store } from "../../../utils/store";
import { Container, H2, H5, Section, Text16, Text18, Wrapper } from '../../atoms';
import ModalProduct from '../../molecules/modalProduct';
import { SORTED as sorted } from "../../../utils/misc";

export default function ProductsDetailSection() {

  let { produto, language } = useParams();

  const { global } = useContext(store);

  const history = useHistory();

  const [activePhoto, setActivePhoto] = useState(-1);
  const [openModal, setOpenModal] = useState(false);
  const [contentModal, setContentModal] = useState(null);
  const [indexTab, setIndexTab] = useState(0);
  const [open, setOpen] = useState(false);
  const [productObj, setProductObj] = useState(null);
  const [arrContent, setArrContent] = useState([]);
  const [metatags, setMetatags] = useState(null);

  const [getProduct, { loading }] = useLazyQuery(
    gql`
        query frontendProduct($slug: String!){
          frontendProduct(slug: $slug){
            id
            name
            thumbnail
            anteTitle
            description
            fullDescription
            functionalities
            integrations
            imagesSlider
            metatags
            subProducts {
              id
              slug
              name
            }
          }
        }
    `,
    {
      fetchPolicy: 'network-only',
      onError: (error) => {
        if (error?.graphQLErrors[0]?.message === "error.resource_not_found") {
          history.push(`/${language}/404`);
        } else {
          history.push(`/${language}/500`);
        }
      },
      onCompleted: (data) => {
        if (data?.frontendProduct) {
          setProductObj(data.frontendProduct);
          if (data.frontendProduct?.metatags) {
            setMetatags(data.frontendProduct.metatags)
          }
        }
      }
    }
  );

  useEffect(() => {
    if (metatags !== null) {
      insertMetatags(metatags)
    }
  }, [metatags]);

  const insertMetatags = (meta) => {
    if (meta !== null) {
      const {
        title,
        description,
        keywords
      } = meta;

      if (title) {
        document.getElementById("ogtitle").setAttribute("content", title);
      }
      if (description) {
        document.getElementById("ogdescription").setAttribute("content", description);
      }
      if (keywords) {
        document.getElementById("keywords").setAttribute("content", keywords.toString());
      }
    }
  }

  useEffect(() => {
    setIndexTab(0);
    window.scrollTo(0, 0);
    getProduct({ variables: { slug: produto } });
  }, [produto]);

  const passData = (data) => {
    setContentModal(data);
    setOpenModal(true);
  };

  useEffect(() => {
    setOpen(false);
  }, [indexTab])

  useEffect(() => {
    if (productObj !== null) {
      let temp = [];
      if (productObj?.subProducts?.length > 0) {
        temp.push(
          {
            key: 'aplicacoes',
            titleTab: global.aplicationLabel,
            content: productObj?.subProducts
          }
        );
      }
      if (productObj?.functionalities?.items?.length > 0) {
        temp.push(
          {
            key: 'funcionalidades',
            titleTab: global.funcLabel,
            content: productObj?.functionalities
          }
        );
      }
      if (productObj?.integrations?.items?.length > 0) {
        temp.push(
          {
            key: 'integracoes',
            titleTab: global.integLabel,
            content: productObj?.integrations
          }
        );
      }
      if (productObj?.fullDescription && productObj?.fullDescription?.description !== undefined && productObj?.fullDescription?.title !== undefined) {
        temp.unshift(
          {
            key: 'descricao',
            titleTab: global.descriptionLabel,
            content: productObj?.fullDescription
          }
        );
      }
      setArrContent(temp);
    }
  }, [productObj, global])

  const renderColumns = (arr) => {

    const chunkArray = (myArray, chunk_size) => {
      var index = 0;
      var arrayLength = myArray.length;
      var tempArray = [];
      var myChunk = false;

      for (index = 0; index < arrayLength; index += Math.ceil(chunk_size)) {
        myChunk = myArray.slice(index, index + Math.ceil(chunk_size));
        tempArray.push(myChunk);
      }
      return tempArray;
    }

    const changeDrop = (block, index) => {
      if (block !== false) {
        setOpen({ block, index })
      } else {
        setOpen(false);
      }
    }

    if (typeof window === 'undefined') {
      global.window = {}
    }

    const dataPerBlock = arr && arr.length / 2;
    const dataDropChunked = arr && chunkArray(arr, dataPerBlock);

    return (
      <DivRowList>
        {(dataDropChunked && dataDropChunked.length > 0) && dataDropChunked.map((item, i) => (
          <DivColumnDrop key={String(i)}>
            {item.map((value, index) => (
              <DivColumn key={String(index)}>
                <DivName>
                  <Text16 color="#5D6368" dangerouslySetInnerHTML={{ __html: value.title }} />
                  {(value.subitems && value.subitems.length > 0) &&
                    <ButtonOpen onClick={() => (open.block === i && open.index === index) ? changeDrop(false) : changeDrop(i, index)}>
                      <img src={(open.block === i && open.index === index) ? '/static/images/minus.svg' : '/static/images/plus.svg'} />
                    </ButtonOpen>
                  }
                </DivName>
                <Collapse isOpened={(open.block === i && open.index === index)}>
                  <DivContainerList>
                    {(value.subitems && value.subitems.length > 0) &&
                      <List>
                        {value.subitems.map((point, z) => (
                          <li key={String(z)}>
                            <Text16 color="#B2B6B9" dangerouslySetInnerHTML={{ __html: point.text }} />
                          </li>
                        ))}
                      </List>
                    }
                  </DivContainerList>
                </Collapse>
              </DivColumn>
            ))}
          </DivColumnDrop>
        ))}
      </DivRowList>
    )

  }

  return (
    <>
      <Section backgroundColor="#D8DADC">
        <Wrapper topInitial noHeight>
          <Container lg>
            <DivBanner>
              <ImageBanner src={productObj?.thumbnail?.url} />
            </DivBanner>
          </Container>
        </Wrapper>
      </Section>
      <Section backgroundColor="#fff">
        <Wrapper top noHeight>
          <Container lg>
            <DivIntro>
              <H2 color="#35383B" font="AeonikRegular" align="center" dangerouslySetInnerHTML={{ __html: productObj?.name }} />
              <Margin margin={4.5} />
              <H5 color="#35383B" font="AeonikRegular" align="center" dangerouslySetInnerHTML={{ __html: productObj?.anteTitle }} />
              <Margin margin={2.5} />
              <Text18 color="#B2B6B9" align="center" maxWidth="764px" dangerouslySetInnerHTML={{ __html: productObj?.description }} />
            </DivIntro>
          </Container>
          {(arrContent && arrContent.length > 0) &&
            <DivContent>
              <DivTabs>
                {arrContent.map((content, index) => (
                  <DivTab key={String(index)} className={indexTab === index ? 'active' : null} onClick={() => setIndexTab(index)}>
                    {content.titleTab}
                  </DivTab>
                ))}
              </DivTabs>
              <DivTabContent>
                <Section backgroundColor="#F7F8F8">
                  <Container lg>
                    <Wrapper both noHeight>
                      {arrContent.map((content, index) => (
                        <DivInnerContent key={String(index)} show={index === indexTab}>
                          {(content.key && content.key === "descricao" && index === indexTab) &&
                            <>
                              <H5 font="AeonikRegular" color="#35383B" maxWidth="522px" dangerouslySetInnerHTML={{ __html: content.content.title }} />
                              <Margin margin={2.5} />
                              <Text18 font="AeonikRegular" height={32} color="#B2B6B9" maxWidth="764px" dangerouslySetInnerHTML={{ __html: content.content.description }} />
                              {(content.content.items && content.content.items.length > 0) &&
                                <>
                                  <Margin margin={9} />
                                  {renderColumns(content?.content?.items)}
                                </>
                              }
                            </>
                          }
                          {(content.key && content.key === "aplicacoes" && index === indexTab) &&
                            <>
                              <DivRow>
                                <H5 color="#5BB990" dangerouslySetInnerHTML={{ __html: `(${content.content.length})` }} />
                                <H5 style={{ marginLeft: 4 }} color="#35383B" dangerouslySetInnerHTML={{ __html: global.prodText + ' ' + productObj?.name }} />
                              </DivRow>
                              <Margin margin={4} />
                              {sorted(content.content).map((item, index) => (
                                <ItemApplication key={String(index)} to={`${produto}/aplicacao${item.slug}`}>
                                  <H5 color="#5D6368" dangerouslySetInnerHTML={{ __html: item.name }} />
                                  <IconApplication src="/static/images/arrow_right_grey.svg" />
                                </ItemApplication>
                              ))}
                            </>
                          }
                          {(content.key && content.key === "funcionalidades" && index === indexTab) &&
                            <>
                              {renderColumns(content?.content?.items)}
                            </>
                          }
                          {(content.key && content.key === "integracoes" && index === indexTab) &&
                            <>
                              {content?.content?.items?.map((item, index) => (
                                <DivItemIntegrations key={String(index)}>
                                  {item.url &&
                                    <Link to={'/' + language + item.url}>
                                      <ItemIntegrations>
                                        <H5 font="AeonikRegular" color="#35383B" dangerouslySetInnerHTML={{ __html: item.name }} />
                                        <IconApplication src="/static/images/arrow_right_grey.svg" />
                                      </ItemIntegrations>
                                    </Link>
                                  }
                                  {!item.url &&
                                    <ItemIntegrations>
                                      <H5 font="AeonikRegular" color="#35383B" dangerouslySetInnerHTML={{ __html: item.name }} />
                                    </ItemIntegrations>
                                  }
                                  {(item.subitems && item.subitems.length > 0) && item.subitems.map((subitem, i) => (
                                    <SubItemIntegrations key={String(i)}>
                                      {subitem.url &&
                                        <Link to={'/' + language + subitem.url} style={{ width: '100%' }}>
                                          <SubSubItemIntegrations>
                                            <H5 font="AeonikRegular" color="#35383B" dangerouslySetInnerHTML={{ __html: subitem.name }} />
                                            <IconApplication src="/static/images/arrow_right_grey.svg" />
                                          </SubSubItemIntegrations>
                                        </Link>
                                      }
                                      {!subitem.url &&
                                        <H5 font="AeonikRegular" color="#5D6368" dangerouslySetInnerHTML={{ __html: subitem.name }} />
                                      }
                                    </SubItemIntegrations>
                                  ))}
                                </DivItemIntegrations>
                              ))}
                            </>
                          }
                        </DivInnerContent>
                      ))}
                    </Wrapper>
                  </Container>
                </Section>
              </DivTabContent>
            </DivContent>
          }
        </Wrapper>
      </Section>
      {(productObj?.imagesSlider?.items && productObj?.imagesSlider?.items.length > 0) &&
        <DivPhotos>
          {productObj.imagesSlider.items.map((photo, index) => (
            <DivPhoto key={String(index)} onMouseEnter={() => setActivePhoto(index)} onMouseLeave={() => setActivePhoto(-1)}>
              <img src={photo?.item?.url} />
              <Overlay show={activePhoto === index} onClick={() => passData(photo)}>
                <ButtonPlus>
                  <img src="/static/images/icon_plus_white.svg" />
                </ButtonPlus>
              </Overlay>
            </DivPhoto>
          ))}
        </DivPhotos>
      }
      <ModalProduct isOpen={openModal} toogleModal={() => setOpenModal(false)} content={contentModal} />
    </>
  )
}

const Margin = styled.div`
  margin-top: ${({ margin }) => margin ? margin * 8 : 8}px;
`;
const DivRow = styled.div`
  display: inline-flex;
`;
const DivBanner = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;
const ImageBanner = styled.img`
  max-width: 100%;
  max-height: 578px;
  object-fit: contain;
`;
const DivIntro = styled.div`
  display: flex;
  flex-flow: column;
  align-items: center;
  justify-content: center;
`;
const DivContent = styled.div`
  display: flex;
  flex-flow: column;
  margin-top: calc(60px + (120 - 60) * ((100vw - 375px) / (1920 - 375)));
  max-width: 100%;
`;
const DivTabs = styled.div`
  display: inline-flex;
  overflow-x: auto;
  overflow-y: hidden;
  max-width: 100%;
`;
const DivTab = styled.div`
  min-width: 372px;
  width: 372px;
  height: 64px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #EBECED;
  font-size: 18px;
  line-height: 22px;
  color: #B2B6B9;
  font-family: 'AeonikRegular', sans-serif;
  transition: all 0.5s ease-in-out;
  &.active{
    background: #F7F8F8;
    color: #35383B;
  }
  :hover{
    cursor: pointer;
  }
  :first-child{
    margin-left: auto;
  }
  :last-child{
    margin-right: auto;
  }
  @media screen and (max-width: 600px){
    min-width: unset;
    width: unset;
    padding: 0 24px;
  }
`;
const DivTabContent = styled.div`
  overflow-x: auto;
  overflow-y: hidden;
  max-width: 100%;
`;
const DivPhotos = styled.div`
  display: inline-flex;
  max-width: 100%;
  overflow-y: hidden;
  overflow-x: auto;
  cursor: grab;
`;
const DivPhoto = styled.div`
  position: relative;
  width: 500px;
  height: 450px;
  min-width: 500px;
  min-height: 450px;
  max-width: 500px;
  max-height: 450px;
  background: #EBECED;
  display: flex;
  align-items: center;
  justify-content: center;
  img{
    max-width: 80%;
    max-height: 100%;
    object-fit: contain;
  }
  @media screen and (max-width:700px){
    max-width: 100%;
    min-width: 100%;
  }
`;
const Overlay = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
  background: #35383B;
  opacity: ${({ show }) => show ? '0.8' : '0'};
  display: flex;
  align-items: center;
  justify-content:center;
  transition: all 0.4s ease-in-out;
  :hover{
    cursor: pointer;
  }
`;
const ButtonPlus = styled.div`
  width: 48px;
  height: 48px;
  border-radius: 48px;
  background-color: #5BB990;
  display: flex;
  align-items: center;
  justify-content: center;
  img{
    width: 16px;
    height: 16px;
    object-fit: contain;
  }
`;
const DivInnerContent = styled.div`
  opacity: 0;
  transition: all 0.6s ease-in-out;
  height: 100%;
  width: 100%;
  box-sizing: border-box;
  display: flex;
  flex-flow: column;
  ${({ show }) => show && css`
    opacity: 1;
  `}
`;
const DivRowList = styled.div`
  display: flex;
  flex-flow: row;
  flex-wrap: wrap;
  margin-right: -20px;
  width: 100%;
`;
const DivColumnDrop = styled.div`
  width: calc(50% - 10px);
  margin-right: 20px;
  border-top: 1px solid #D8DADC;
  :last-of-type{
    margin-right: 0;
  }
  @media screen and (max-width: 1000px){
    :last-of-type{
      border-top: 0;
    }
    margin-right: 0;
    width: 100%;
  } 
`;
const DivColumn = styled.div`
  display: flex;
  flex-flow: column;
  box-sizing: border-box;
  width: 100%;
  border-bottom: 1px solid #D8DADC;
  .ReactCollapse--collapse {
    transition: height 500ms;
  }
`;
const DivName = styled.div`
  padding: 16px 8px;
  display: flex;
  flex-flow: row;
  align-items: center;
  justify-content: space-between;
`;
const ButtonOpen = styled.div`
  width: 16px;
  height: 16px;
  margin-left: 24px;
  :hover{
    cursor: pointer;
  }
  img{
    height: 100%;
    width: 100%;
    object-fit: contain;
  }
`;
const DivContainerList = styled.div`
  width: 100%;
  box-sizing: border-box;
  background-color: #F9FAFA;
  transition: all 0.4s ease-in-out;
  border-top: 1px solid #D8DADC;
  box-sizing: border-box;
  padding-left: calc(25px + (40 - 25) * ((100vw - 375px) / (1920 - 375)));
  padding-top: 16px;
  padding-bottom: 16px;
`;
const List = styled.ul`
  list-style: none;
  padding: 0;
  margin: 0;
  li { 
    display: flex;
    flex-flow: row; 
    margin-bottom: 16px;
  }
  li::last-child { 
    margin-bottom: 0;
  }
  li::before {
    content: "•";
    padding-top: 2px;
    padding-right: 8px;
    color: #B2B6B9;
  }
`;
const IconApplication = styled.img`
  width: 13px;
  height: 12px;
  object-fit: contain;
  transform: translateX(0);
  transition: all 0.4s ease-in-out;
`;
const ItemApplication = styled(Link)`
  display: inline-flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px 20px 20px 0;
  border-bottom: 1px solid #D8DADC;
  width: 100%;
  max-width: 100%;
  box-sizing: border-box;
  transition: all 0.4s ease-in-out;
  :first-of-type{
    border-top: 1px solid #D8DADC;
  }
  :hover{
    ${IconApplication}{
      transform: translateX(10px);
    }
  }
`;

const DivItemIntegrations = styled.div`
  display: flex;
  flex-flow: column;
  :last-of-type{
    margin-bottom: 0;
  }
`;
const ItemIntegrations = styled.div`
  display: inline-flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px 20px 20px 20px;
  width: 100%;
  max-width: 100%;
  box-sizing: border-box;
  transition: all 0.4s ease-in-out;
  border-top: 1px solid #D8DADC;
  :hover{
    ${IconApplication}{
      transform: translateX(10px);
    }
  }
`;
const SubItemIntegrations = styled.div`
  display: inline-flex;
  justify-content: space-between;
  align-items: center;
  padding: 16px 20px 16px 32px;
  border-bottom: 1px solid #D8DADC;
  width: 100%;
  max-width: 100%;
  box-sizing: border-box;
  transition: all 0.4s ease-in-out;
  :first-of-type{
    border-top: 1px solid #D8DADC;
  }
  :hover{
    ${IconApplication}{
      transform: translateX(10px);
    }
  }
`;
const SubSubItemIntegrations = styled.div`
  display: inline-flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  box-sizing: border-box;
  transition: all 0.4s ease-in-out;
  :hover{
    ${IconApplication}{
      transform: translateX(10px);
    }
  }
`;