import React from 'react';
import useLang from '../../utils/useLang';
import NewsDetail from '../organisms/newsDetail';

function Noticia() {

  return (
    <NewsDetail/>
  )
}
export default useLang(Noticia);