import React, { useContext } from 'react';
import { useCookies } from 'react-cookie';
import { useHistory, useParams } from 'react-router-dom';
import { getLanguage, setLanguage } from './language';
import { store } from './store';

export default (Component) => {
    return () => {
        const { defaultLanguage, setLang } = useContext(store);

        const [cookies, setCookie] = useCookies(['language']);

        const { language } = useParams();
        const history = useHistory();

        if (!language) {
            if (cookies.language) {
                setLanguage(cookies.language);
                setLang(cookies.language);
                history.push(`/${cookies.language}/`);
            } else {
                if (defaultLanguage !== null && defaultLanguage !== '') {
                    setCookie('language', defaultLanguage, { path: '/' });
                    setLanguage(defaultLanguage);
                    setLang(defaultLanguage);
                    history.push(`/${defaultLanguage}/`);
                }
            }
        } else {
            if (!cookies.language) {
                setCookie('language', language, { path: '/' });
            }
            setLanguage(language);
        }

        if (getLanguage() !== null) {
            return <Component />
        } else {
            if (defaultLanguage !== null && defaultLanguage !== '') {
                setCookie('language', defaultLanguage, { path: '/' });
                setLanguage(defaultLanguage);
                setLang(defaultLanguage);
                history.push(`/${defaultLanguage}/`);
            }
        }

        return null;

    }
}