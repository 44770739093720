import React, { useEffect, useState } from 'react';
import { ReactSVG } from 'react-svg';
import styled, { css } from 'styled-components';
import { Container, H1, Section, Text14, Wrapper } from '../../atoms';
import { Intro } from '../../molecules';
import Lottie from 'react-lottie';
import * as animationData from './medidata_logo.json'
import * as Scroll from 'react-scroll';

const LinkScroll = Scroll.Link;

export default function BannerAbout({ textHand, title, img, textKnow }) {

  const [show, setShow] = useState(false);
  const [showAnimation, setShowAnimation] = useState(false);

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
  })

  const handleScroll = () => {
    let scrollY = window.scrollY;
    let height = window.innerHeight;
    if (scrollY >= (height / 2)) {
      if (!show) {
        setShow(true);
        setTimeout(() => {
          setShowAnimation(true);
        }, 600);
      }
    }
    else {
      if (show) {
        setShow(false);
        setTimeout(() => {
          setShowAnimation(false);
        }, 600);
      }
    }
  }

  const defaultOptions = {
    loop: false,
    autoplay: false,
    animationData: animationData.default,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice'
    }
  };

  return (
    <>
      <Section backgroundColor="#5BB990">
        <Wrapper bothInitial full >
          <Container lg>
            <DivContainer>
              <DivIntro>
                <H1 font="Rockness" color="#368D67" dangerouslySetInnerHTML={{ __html: textHand }} style={{ marginBottom: -16, fontSize: 56 }} />
                <Intro
                  titleSize="H2"
                  title={title}
                  titleColor="#35383B"
                  titleMaxWidth="960px"
                  titleFontFamily="AeonikRegular"
                />
                <DivRow style={{ marginTop: 52 }}>
                  <Text14 color="#368D67" dangerouslySetInnerHTML={{ __html: textKnow }} />
                  <LinkScroll
                    to='about'
                    spy={true}
                    smooth={true}
                    duration={500}
                  >
                    <DivArrow>
                      <Arrow src="/static/images/arrow_down.svg" />
                    </DivArrow>
                  </LinkScroll>
                </DivRow>
              </DivIntro>
            </DivContainer>
          </Container>
        </Wrapper>
      </Section>
      <DivImage className='about'>
        <Image show={show} src={img?.url} />
        <DivLottie show={show}>
          <div style={{ position: 'relative' }}>
            <Lottie
              options={defaultOptions}
              isStopped={!showAnimation}
            />
          </div>
        </DivLottie>
      </DivImage>
    </>
  )
}

const Margin = styled.div`
  margin-top: ${({ margin }) => margin ? margin * 8 : 8}px;
`;
const DivContainer = styled.div`
  display: flex;
  flex-flow: row;
  height: 100%;
`;
const DivIntro = styled.div`
  display: flex;
  min-height: 100%;
  flex-flow: column;
  justify-content: center;
  @media screen and (max-width:700px){
    margin-bottom: 120px
  }
`;
const DivRow = styled.div`
  display: flex;
  flex-flow: row;
  align-items: center;
`;
const DivArrow = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 52px;
  height: 52px;
  border: 1px solid #368D67;
  border-radius: 26px;
  margin-left: 24px;
`;
const Arrow = styled(ReactSVG)`
  object-fit: contain;
  animation: bounce 0.5s; 
  animation-direction: alternate; 
  animation-timing-function: cubic-bezier(.5, 0.05, 1, .5); 
  animation-iteration-count: infinite;
  height: 13px;
  width: 12px;
  object-fit: contain;
  @keyframes bounce { 
    from { 
      transform: translateY(-2px); 
    } 
    to { 
      transform: translateY(2px); 
    } 
  } 
  svg{
    path{
      transition: all 0.5s ease-in-out;
      stroke: #368D67;
    }
  }
`;
const DivImage = styled.div`
  position: relative;
  margin-top: -104px;
  background-color: transparent;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 824px;
  max-height: 91.5vh;
  overflow: hidden;
`;
const Image = styled.img`
  width: 90%;
  height: 100%;
  object-fit: cover;
  transition: all 0.4s ease-in-out;
  ${({ show }) => show && css`
    width: 100%;
  `}
`;
const DivLottie = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 50%;
  height: 50%;
  opacity: 0;
  transition: all 0.4s ease-in-out;
  margin: auto;
  z-index: 1;
  ${({ show }) => show && css`
    opacity: 1;
  `}
`;