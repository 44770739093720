import React from 'react';
import styled, { css } from 'styled-components';
import Fade from 'react-reveal/Fade';

export default function Section(
  {
    className,
    backgroundColor,
    twoColors,
    children,
    onTop,
    onTop2,
    margin,
    hiddenX,
    noFade,
    ...props
  }
) {

  return (
    <DivSection hiddenX={hiddenX} className={className} backgroundColor={backgroundColor} twoColors={twoColors} indexDiv={onTop} indexDiv2={onTop2} margin={margin} {...props}>
      {noFade &&
        <>
          {children}
        </>
      }
      {!noFade &&
        <Fade bottom cascade ssrFadeout>
          {children}
        </Fade>
      }
    </DivSection>
  )
}


const DivSection = styled.div`
  position: relative;
  margin-top: ${({ margin }) => margin ? margin : '0'}px;
  background-color: ${({ backgroundColor }) => backgroundColor ? backgroundColor : 'white'};
  ${({ hiddenX }) => hiddenX && css`
    overflow-x:hidden;
  `}
  ${({ twoColors }) => twoColors && css`
    background: linear-gradient(180deg, #202020 65%, #ffffff 35%);
  `};
  ${({ indexDiv }) => indexDiv && css`
    z-index:1;
  `};
  ${({ indexDiv2 }) => indexDiv2 && css`
    z-index:2;
  `};
`;